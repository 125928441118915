"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm._m(0), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Loại video",
      prop: "name",
      width: "300px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.functionType ? _c("div", [_vm._v(" " + _vm._s(_vm.FunctionTypeTrans[row.functionType]) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Video"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.videoGuide ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Link Tiếng Việt: ")]), _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: row.videoGuide
          }
        }, [_vm._v(" " + _vm._s(row.videoGuide) + " ")])], 1)]) : _vm._e(), row.videoGuideEn ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Link Tiếng Anh: ")]), _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: row.videoGuideEn
          }
        }, [_vm._v(" " + _vm._s(row.videoGuideEn) + " ")])], 1)]) : _vm._e(), row.videoGuideJp ? _c("div", [_c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Link Tiếng Nhật: ")]), _c("el-link", {
          attrs: {
            type: "primary",
            target: "_blank",
            href: row.videoGuideJp
          }
        }, [_vm._v(" " + _vm._s(row.videoGuideJp) + " ")])], 1)]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "200px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "flex-direction": "column",
            "align-items": "center"
          }
        }, [_c("el-button", {
          staticStyle: {
            width: "120px",
            "margin-left": "0",
            "margin-top": "4px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdateUserManual(row);
            }
          }
        }, [_vm._v(" Cập nhật ")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit,
      pageSizes: [10, 20, 30, 50, 100]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("UserManualModal", {
    ref: "UserManualModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  })]);
}];
render._withStripped = true;