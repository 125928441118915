"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "recommend-book-modal",
    attrs: {
      title: "Lịch sử chat với AI",
      visible: _vm.visible,
      width: "800px",
      top: "30px",
      "append-to-body": "",
      "close-on-click-modal": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", [_c("div", {
    staticClass: "chat"
  }, [_vm.visible ? _c("chat-window", {
    attrs: {
      height: _vm.heightChat,
      "current-user-id": 0,
      messages: _vm.messages,
      "show-files": false,
      "show-audio": false,
      "show-add-room": false,
      "show-emojis": false,
      "show-reaction-emojis": false,
      "show-footer": false,
      "rooms-list-opened": false,
      "messages-loaded": _vm.messagesLoaded,
      "rooms-loaded": true,
      rooms: _vm.rooms,
      "room-actions": [],
      "room-id": _vm.rooms[0].roomId,
      "loading-rooms": false
    },
    on: {
      "fetch-messages": _vm.fetchMoreMessage,
      "fetch-more-rooms": function fetchMoreRooms() {
        return null;
      }
    }
  }) : _vm._e()], 1)]), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v("Đóng")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;