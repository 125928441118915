"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.values.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh",
      prop: "image"
    }
  }, [_c("SingleImage", {
    attrs: {
      width: "120",
      height: "120",
      pathImage: _vm.form.image,
      urlUpload: "/v1/admin/book/upload"
    },
    on: {
      "upload:success": _vm.setImage
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên quà tặng",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại quà tặng",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(Object.values(_vm.giftTypeTrans), function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _vm.form.type == _vm.GiftType.Book ? _c("el-form-item", {
    attrs: {
      label: "Bài tập",
      prop: "bookId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      clearable: "",
      filterable: ""
    },
    model: {
      value: _vm.form.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookId", $$v);
      },
      expression: "form.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Giá trị (VNĐ)",
      prop: "value"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      controls: "false"
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;