"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.resetData
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.MediaType.Video
    },
    model: {
      value: _vm.form.reminderConfig.mediaType,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "mediaType", $$v);
      },
      expression: "form.reminderConfig.mediaType"
    }
  }, [_vm._v("Video")]), _c("el-radio", {
    attrs: {
      label: _vm.MediaType.Image
    },
    model: {
      value: _vm.form.reminderConfig.mediaType,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "mediaType", $$v);
      },
      expression: "form.reminderConfig.mediaType"
    }
  }, [_vm._v("Hình ảnh")]), _vm.form.reminderConfig.mediaType == _vm.MediaType.Video ? _c("el-form-item", [_c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res);
      },
      "file-list": _vm.videoFileList,
      "on-remove": _vm.handleRemoveVideo,
      accept: "video/*"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video")])], 1)], 1) : _vm._e(), _vm.form.reminderConfig.mediaType == _vm.MediaType.Image ? _c("el-form-item", {
    attrs: {
      prop: "avatar"
    }
  }, [_c("SingleImage", {
    attrs: {
      width: "120",
      height: "120",
      pathImage: _vm.form.reminderConfig.media,
      urlUpload: "/v1/admin/book/upload"
    },
    on: {
      "upload:success": _vm.setMedia
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.reminderConfig.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "name", $$v);
      },
      expression: "form.reminderConfig.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.reminderConfig.nameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "nameEn", $$v);
      },
      expression: "form.reminderConfig.nameEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.reminderConfig.nameJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "nameJp", $$v);
      },
      expression: "form.reminderConfig.nameJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Chọn thời gian",
      prop: "chooseTimeNotify"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Chọn thời gian nhắc",
      filterable: ""
    },
    model: {
      value: _vm.form.reminderConfig.chooseTimeNotify,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "chooseTimeNotify", $$v);
      },
      expression: "form.reminderConfig.chooseTimeNotify"
    }
  }, _vm._l(_vm.timeOptions, function (time) {
    return _c("el-option", {
      key: time,
      attrs: {
        label: time,
        value: time
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Gói",
      prop: "bookId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookId", $$v);
      },
      expression: "form.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Audio"
    }
  }, [[_c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": _vm.handleUploadAudioOk,
      "file-list": _vm.audioFileList,
      "on-remove": _vm.handleRemoveAudio
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload audio")])], 1)]], 2), _c("el-form-item", {
    attrs: {
      label: "Quan trọng",
      prop: "isPriority"
    }
  }, [_c("span", [_vm._v("Không")]), _c("el-switch", {
    staticStyle: {
      margin: "0 8px"
    },
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949"
    },
    model: {
      value: _vm.form.reminderConfig.isPriority,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "isPriority", $$v);
      },
      expression: "form.reminderConfig.isPriority"
    }
  }), _c("span", [_vm._v("Có")])], 1), _c("el-form-item", {
    attrs: {
      label: "Lặp lại",
      prop: "loopType"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.reminderConfig.loopType,
      callback: function callback($$v) {
        _vm.$set(_vm.form.reminderConfig, "loopType", $$v);
      },
      expression: "form.reminderConfig.loopType"
    }
  }, _vm._l(_vm.loopTypeTrans, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;