"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Chi tiết",
      visible: _vm.visible,
      width: "900px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.splitData,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Thời gian",
      "min-width": 10
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(" " + _vm._s(_vm.$formatDateTime(row === null || row === void 0 ? void 0 : row.createdAt)) + " ")]), _c("span", [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row === null || row === void 0 ? void 0 : row.time)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thiết bị",
      "min-width": 10
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(" " + _vm._s(row === null || row === void 0 ? void 0 : row.deviceId) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Video",
      "min-width": 10
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(" " + _vm._s(row === null || row === void 0 ? void 0 : row.videoId) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị góc",
      "min-width": 30
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row !== null && row !== void 0 && row.poseValue.length ? _c("div", _vm._l(row === null || row === void 0 ? void 0 : row.poseValue, function (pose, index) {
          return _c("div", {
            key: row === null || row === void 0 ? void 0 : row.poseValue.name
          }, [(row === null || row === void 0 ? void 0 : row.poseError) == pose.name ? _c("div", [_c("span", {
            staticStyle: {
              color: "red"
            }
          }, [_vm._v(_vm._s(pose.name) + ": ")]), _c("span", [_vm._v("[" + _vm._s(_vm.$formatNumberVN(pose.threshold, 6)) + ", ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(pose.number, 6)) + "]")])]) : _c("div", [_c("span", [_vm._v(_vm._s(pose.name) + ": ")]), _c("span", [_vm._v("[" + _vm._s(_vm.$formatNumberVN(pose.threshold, 6)) + ", ")]), _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(pose.number, 6)) + "]")])])]);
        }), 0) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ảnh",
      "min-width": 40
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("img", {
          attrs: {
            src: _vm.$url.image(row.image),
            width: "300",
            alt: ""
          }
        })];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div"), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;