"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-dialog", {
    attrs: {
      title: "Danh sách lộ trình AI đưa ra",
      visible: _vm.visible,
      width: "600px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.dataTable,
      border: "",
      fit: "",
      height: "500"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Lộ trình"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row !== null && row !== void 0 && row.routeName ? _c("label", [_vm._v(_vm._s(row.routeName))]) : _c("label", [_vm._v("Lộ trình " + _vm._s(row.currentOrderNumber + 1))]), _c("br"), row.dataAIJson ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-view"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleOpenDetailRoadmap(row);
            }
          }
        }, [_vm._v("Xem chi tiết")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }])
  })], 1)], 1), _c("question-detail-modal", {
    ref: "QuestionDetailModal"
  }), _c("roadmap-detail-modal", {
    ref: "RoadmapDetailModal"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;