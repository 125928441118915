"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productPackApi = void 0;
var _request = require("@/utils/request");
var productPackApi = exports.productPackApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: "/v1/admin/productPack",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: "/v1/admin/productPack",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/productPack/".concat(id),
      method: "patch",
      data: data
    });
  },
  sync: function sync(id) {
    return (0, _request.request)({
      url: "/v1/admin/productPack/".concat(id, "/sync"),
      method: "patch"
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/productPack/".concat(id),
      method: "delete"
    });
  }
};