"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container",
    staticStyle: {
      "margin-bottom": "8px"
    }
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    staticStyle: {
      width: "250px"
    },
    attrs: {
      size: "medium",
      placeholder: "Tìm kiếm theo mã đơn hàng"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sản phẩm")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: "",
      filterable: ""
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "bookId", $$v);
      },
      expression: "query.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Phương thức thanh toán")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Tất cả",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.paymentType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "paymentType", $$v);
      },
      expression: "query.paymentType"
    }
  }, _vm._l(_vm.PaymentTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Tất cả",
      size: "52mm"
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.OrderStatusTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Khoảng thời gian")]), _c("el-date-picker", {
    staticStyle: {
      height: "36px"
    },
    attrs: {
      type: "daterange",
      "range-separator": "-",
      clearable: "",
      "start-placeholder": "Từ ngày",
      format: "dd/MM/yyyy",
      "end-placeholder": "Đến ngày"
    },
    on: {
      change: _vm.handleChangeFilter
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.showModal
    }
  }, [_vm._v("Xuất file (Excel)")])], 1), _c("el-dialog", {
    attrs: {
      title: "Nhập số trang",
      visible: _vm.isModalVisible,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isModalVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.pageRange
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Từ trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.from,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "from", _vm._n($$v));
      },
      expression: "pageRange.from"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đến trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.to,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "to", _vm._n($$v));
      },
      expression: "pageRange.to"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.isModalVisible = false;
      }
    }
  }, [_vm._v("Hủy")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitPageRange
    }
  }, [_vm._v("Xác nhận")])], 1)], 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "margin-bottom": "16px",
      gap: "50px"
    }
  }, [_c("div", [_c("b", [_vm._v("Tổng đơn hàng:")]), _c("span", [_vm._v(" " + _vm._s(_vm.summary.totalOrder))])]), _c("div", [_c("b", [_vm._v("Tổng tiền doanh thu:")]), _c("span", [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.summary.totalAmount)))])])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      stripe: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã đơn hàng",
      prop: "code",
      width: "100px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.code) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tổng đơn hàng",
      prop: "amount",
      align: "right",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.amount)) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số tiền KH đã thanh toán",
      prop: "priceCurrencyAmountConvert",
      align: "right",
      width: "170px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.status == _vm.OrderStatus.Complete ? _c("div", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [row.priceCurrencyCode == "VND" ? _c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.priceCurrencyAmountConvert)))]) : _c("span", [_vm._v(" " + _vm._s(row.priceCurrencyAmountConvert))]), _vm._v(" " + _vm._s(row === null || row === void 0 ? void 0 : row.priceCurrencyCode) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chi tiết",
      prop: "amount",
      width: "350"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _row$orderDetails$, _row$book, _row$orderDetails$2, _row$book2, _row$orderDetails$3, _row$book3, _row$orderDetails$4;
        var row = _ref4.row;
        return [row.book || row.orderDetails.length && (_row$orderDetails$ = row.orderDetails[0]) !== null && _row$orderDetails$ !== void 0 && _row$orderDetails$.book ? _c("div", [_c("div", {
          staticClass: "item"
        }, [_c("h3", {
          staticStyle: {
            margin: "0px"
          }
        }, [_vm._v(" " + _vm._s(((_row$book = row.book) === null || _row$book === void 0 ? void 0 : _row$book.name) || ((_row$orderDetails$2 = row.orderDetails[0]) === null || _row$orderDetails$2 === void 0 || (_row$orderDetails$2 = _row$orderDetails$2.book) === null || _row$orderDetails$2 === void 0 ? void 0 : _row$orderDetails$2.name)) + " ")]), _c("div", [_c("strong", [_vm._v("Số lượng:")]), _vm._v(" 1")]), _c("div", [_c("strong", [_vm._v("Giá trước khuyến mãi (VNĐ):")]), _vm._v(" " + _vm._s(_vm.formatCurrency((row === null || row === void 0 || (_row$book2 = row.book) === null || _row$book2 === void 0 ? void 0 : _row$book2.discountPrice) || ((_row$orderDetails$3 = row.orderDetails[0]) === null || _row$orderDetails$3 === void 0 || (_row$orderDetails$3 = _row$orderDetails$3.book) === null || _row$orderDetails$3 === void 0 ? void 0 : _row$orderDetails$3.discountPrice))) + " ")]), _c("div", [_c("strong", [_vm._v("Giá sau khuyến mãi (VNĐ):")]), _vm._v(" " + _vm._s(_vm.formatCurrency((row === null || row === void 0 || (_row$book3 = row.book) === null || _row$book3 === void 0 ? void 0 : _row$book3.price) || ((_row$orderDetails$4 = row.orderDetails[0]) === null || _row$orderDetails$4 === void 0 || (_row$orderDetails$4 = _row$orderDetails$4.book) === null || _row$orderDetails$4 === void 0 ? void 0 : _row$orderDetails$4.price))) + " ")]), _c("div", [_c("strong", [_vm._v("Ngày mua:")]), _vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")]), row.type == _vm.BookType.Product ? _c("div", [_c("strong", [_vm._v("Địa chỉ giao hàng:")]), _vm._v(" " + _vm._s(row.deliveryAddress) + " ")]) : _vm._e(), row.type == _vm.BookType.Product ? _c("div", [_c("strong", [_vm._v("Tên người nhận hàng:")]), _vm._v(" " + _vm._s(row.deliveryName) + " ")]) : _vm._e(), row.type == _vm.BookType.Product ? _c("div", [_c("strong", [_vm._v("SĐT người nhận hàng:")]), _vm._v(" " + _vm._s(row.deliveryPhone) + " ")]) : _vm._e()])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Người mua",
      prop: "staff",
      width: "170px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.staff ? _c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row !== null && row !== void 0 && row.staff.avatar ? _vm.$url.image(row.staff.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.staff.name))])]) : _vm._e(), row !== null && row !== void 0 && row.staff ? _c("div", [_c("div", [_c("strong", [_vm._v("Username:")]), _vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.staff.username))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phương thức thanh toán",
      prop: "paymentType",
      width: "170px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.paymentType ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.PaymentTypeTrans[row.paymentType]))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái",
      width: "120px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [row.type == _vm.BookType.Product ? _c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function command(newStatus) {
              return _vm.handleStatusChange(row, newStatus);
            }
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Ấn vào để cập nhật trạng thái",
            placement: "top"
          }
        }, [_c("span", {
          staticClass: "status-dropdown-label"
        }, [_c("el-tag", {
          staticStyle: {
            width: "100%"
          },
          attrs: {
            type: _vm.OrderStatusTransTagColor[row.status],
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s(_vm.OrderStatusTrans[row.status]) + " ")])], 1)]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, _vm._l(_vm.OrderStatusTrans, function (status, key) {
          return key !== _vm.OrderStatus.Pending ? _c("el-dropdown-item", {
            key: key,
            attrs: {
              command: key
            }
          }, [_c("span", {
            staticClass: "status-item"
          }, [_c("el-tag", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              type: _vm.OrderStatusTransTagColor[key],
              effect: "dark"
            }
          }, [_vm._v(" " + _vm._s(status) + " ")])], 1)]) : _vm._e();
        }), 1)], 1) : _c("span", {
          staticClass: "status-dropdown-label"
        }, [_c("el-tag", {
          attrs: {
            type: _vm.OrderStatusTransTagColor[row.status],
            effect: "dark"
          }
        }, [_vm._v(" " + _vm._s(_vm.OrderStatusTrans[row.status]) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại",
      align: "center",
      width: "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("span", [_vm._v(_vm._s(_vm.orderTypeTrans[row.type]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo",
      align: "center",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150",
      align: "center",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Chi tiết ")]), row.isTest ? _c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            loading: row.loadingDelete
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v(" Xóa ")]) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("order-modal", {
    ref: "OrderModal",
    on: {
      "submit:ok": _vm.fetchData
    }
  }), _c("create-order-modal", {
    ref: "CreateOrderModal",
    on: {
      "submit:ok": _vm.refresh
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;