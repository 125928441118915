"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-right": "4px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Chế độ tìm kiếm")]), _c("el-radio", {
    attrs: {
      label: "BASIC"
    },
    model: {
      value: _vm.queryMode,
      callback: function callback($$v) {
        _vm.queryMode = $$v;
      },
      expression: "queryMode"
    }
  }, [_vm._v(" Cơ bản ")]), _c("el-radio", {
    attrs: {
      label: "ADV"
    },
    model: {
      value: _vm.queryMode,
      callback: function callback($$v) {
        _vm.queryMode = $$v;
      },
      expression: "queryMode"
    }
  }, [_vm._v(" Nâng cao ")])], 1), _vm.queryMode == "ADV" ? _c("div", {
    staticClass: "query-box"
  }, [_vm._l(_vm.queryMultiple, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "query-field"
    }, [index != 0 ? _c("div", {
      staticClass: "line-split"
    }) : _vm._e(), _c("div", {
      staticClass: "label"
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(" Trường tìm kiếm ")]), _vm.queryMultiple.length > 1 ? _c("i", {
      staticClass: "el-icon-delete query-delete",
      on: {
        click: function click($event) {
          return _vm.handleClearQuery(index);
        }
      }
    }) : _vm._e()]), _c("el-select", {
      attrs: {
        placeholder: "Chọn trường cần tìm",
        clearable: _vm.queryMultiple.length > 1
      },
      on: {
        clear: function clear($event) {
          return _vm.handleClearQuery(index);
        }
      },
      model: {
        value: item.field,
        callback: function callback($$v) {
          _vm.$set(item, "field", $$v);
        },
        expression: "item.field"
      }
    }, _vm._l(_vm.searchFields, function (item) {
      return _c("el-option", {
        key: item.id,
        attrs: {
          label: item.label,
          value: item.field
        }
      });
    }), 1), item.field ? _c("el-input", {
      staticStyle: {
        "margin-top": "8px"
      },
      attrs: {
        placeholder: "Từ khóa"
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), index != _vm.queryMultiple.length - 1 ? _c("div", {
      staticStyle: {
        "margin-top": "4px"
      }
    }, [_c("span", {
      staticStyle: {
        "font-size": "14px",
        color: "#989999"
      }
    }, [_vm._v(" Kết hợp ")]), _c("el-radio", {
      attrs: {
        label: "AND"
      },
      model: {
        value: item.operator,
        callback: function callback($$v) {
          _vm.$set(item, "operator", $$v);
        },
        expression: "item.operator"
      }
    }, [_vm._v(" Và ")]), _c("el-radio", {
      attrs: {
        label: "OR"
      },
      model: {
        value: item.operator,
        callback: function callback($$v) {
          _vm.$set(item, "operator", $$v);
        },
        expression: "item.operator"
      }
    }, [_vm._v(" Hoặc ")])], 1) : _vm._e()], 1);
  }), _c("el-link", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleAddQuery
    }
  }, [_vm._v(" + Thêm trường ")])], 2) : _c("section", [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    staticStyle: {
      width: "350px"
    },
    attrs: {
      size: "medium",
      placeholder: "Tìm tên sách"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchData.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thể loại")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.bookCategoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "bookCategoryId", $$v);
      },
      expression: "query.bookCategoryId"
    }
  }, _vm._l(_vm.bookCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " ").concat(item.syncId ? "- ".concat(item.syncId) : ""),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Định dạng sách")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.bookType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "bookType", $$v);
      },
      expression: "query.bookType"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Sách giấy",
      value: _vm.BookType.Physical
    }
  }), _c("el-option", {
    attrs: {
      label: "Sách điện tử",
      value: _vm.BookType.EBook
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Loại đồng bộ")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.syncFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "syncFrom", $$v);
      },
      expression: "query.syncFrom"
    }
  }, _vm._l(_vm.BookSyncFromTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Kho")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.warehouseId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "warehouseId", $$v);
      },
      expression: "query.warehouseId"
    }
  }, _vm._l(_vm.warehouses, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)]), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchData
    }
  }, [_vm._v("Tìm")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    staticClass: "font-bold",
    attrs: {
      type: "primary",
      size: "medium",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm sách")])], 1)]), _c("el-dropdown", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      trigger: "click"
    }
  }, [_c("el-button", {
    attrs: {
      size: "medium",
      type: "primary",
      disabled: !_vm.selectedRows.length
    }
  }, [_vm._v(" Thao tác hàng loạt "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function click($event) {
        return _vm.handlePublishBatch.apply(null, arguments);
      }
    }
  }, [_c("span", [_vm._v("Phát hành")])]), _c("el-dropdown-item", {
    nativeOn: {
      click: function click($event) {
        return _vm.deleteBatch.apply(null, arguments);
      }
    }
  }, [_c("span", [_vm._v("Xóa")])])], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    },
    on: {
      "selection-change": _vm.handleSelect
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      align: "center",
      type: "selection"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên sách",
      prop: "name",
      width: "155"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("img", {
          staticStyle: {
            "border-radius": "5px",
            width: "40px"
          },
          attrs: {
            src: _vm.$url.image(row.thumbnail),
            alt: ""
          }
        }), _c("br"), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Việt: ")]), row.name ? _c("label", [_vm._v(_vm._s(row.name))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Anh: ")]), row.nameEn ? _c("label", [_vm._v(_vm._s(row.nameEn))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Tiếng Nhật: ")]), row.nameJp ? _c("label", [_vm._v(_vm._s(row.nameJp))]) : _c("label", [_vm._v("Chưa cập nhật")])]), _c("div", [_c("span", [_vm._v("- Số trang: " + _vm._s(row.totalPage))])]), row.code ? _c("div", [_c("span", [_vm._v("- Mã nội bộ: " + _vm._s(row.code))])]) : _vm._e(), row.type ? _c("div", [_c("span", [_vm._v("- Định dạng sách: " + _vm._s(_vm.bookTypeTrans[row.type]))])]) : _vm._e(), row.syncFrom ? _c("div", [_c("span", [_vm._v("- Loại đồng bộ: " + _vm._s(_vm.BookSyncFromTrans[row.syncFrom]))])]) : _vm._e(), row.isbnCode ? _c("div", [_c("span", [_vm._v("- ISBN: " + _vm._s(row.isbnCode))])]) : _vm._e(), row.publishDate ? _c("div", [_c("span", [_vm._v("- Năm xuất bản: " + _vm._s(row.publishDate))])]) : _vm._e(), row.publisher ? _c("div", [_c("span", [_vm._v("- Nhà xuất bản: " + _vm._s(row.publisher))])]) : _vm._e(), row.syncId ? _c("div", [_c("span", [_vm._v("- SyncId/OtherId: " + _vm._s(row.syncId))])]) : _vm._e(), row.source ? _c("div", [_c("span", [_vm._v("- Nguồn: " + _vm._s(row.source))])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Phát hành"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.isPublished ? _c("el-tag", {
          attrs: {
            type: "success",
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(" Đã phát hành ")]) : _c("el-tag", {
          attrs: {
            type: "warning",
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(" Chưa phát hành ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thể loại",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tác giả",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("span", [_vm._v(_vm._s(row.authors.map(function (e) {
          return e.name;
        }).join(", ")))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Kho",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("span", [_vm._v(_vm._s(row.warehouses.map(function (e) {
          return e.name;
        }).join(", ")))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lượt xem",
      prop: "viewCount",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("span", [_vm._v(_vm._s(_vm.$formatNumberVN(row.viewCount)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chương"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.bookChapters.length ? _c("span", [_vm._v(" " + _vm._s(row.bookChapters.length) + " chương ")]) : _vm._e(), _c("div", [row.type == _vm.BookType.EBook ? _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdateChapter(row);
            }
          }
        }, [_vm._v("Quản lý")]) : _vm._e()], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đánh giá, bình luận"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", [_c("img", {
          attrs: {
            src: _vm.starIcon,
            width: "20"
          }
        }), _c("span", {
          staticStyle: {
            "font-weight": "bold",
            "vertical-align": "middle"
          }
        }, [_vm._v(_vm._s(row.totalRate == 0 ? "Mới" : (row.totalStar / row.totalRate).toFixed(1)))]), row.totalRate ? _c("div", [_c("span", [_vm._v(_vm._s(row.totalRate) + " đánh giá")])]) : _vm._e()]), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-view"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewRate(row);
            }
          }
        }, [_vm._v("Xem")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "flex-direction": "column",
            "align-items": "center"
          }
        }, [row.bookMeta.length ? _c("el-button", {
          staticStyle: {
            width: "120px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-coordinate"
          },
          on: {
            click: function click($event) {
              return _vm.viewBarcode(row);
            }
          }
        }, [_vm._v("Xem meta")]) : _vm._e(), _c("el-button", {
          staticStyle: {
            width: "120px",
            "margin-left": "0",
            "margin-top": "4px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        }, [_vm._v(" Cập nhật ")]), _c("el-button", {
          staticStyle: {
            width: "120px",
            "margin-left": "0",
            "margin-top": "4px"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v(" Xóa ")])], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit,
      pageSizes: [10, 20, 30, 50, 100]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("BookModal", {
    ref: "BookModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("BookChapterModal", {
    ref: "BookChapterModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("RatingModal", {
    ref: "RatingModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("BookMetaModal", {
    ref: "BookMetaModal"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;