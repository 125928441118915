"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "append-to-body": "",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên lựa chọn gói (Tiếng Việt)",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên lựa chọn  gói (Tiếng Anh)",
      prop: "nameEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameEn", $$v);
      },
      expression: "form.nameEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên lựa chọn gói (Tiếng Nhật)",
      prop: "nameJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameJp", $$v);
      },
      expression: "form.nameJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giá (VNĐ)",
      prop: "price"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giá (USD)",
      prop: "priceEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.priceEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceEn", $$v);
      },
      expression: "form.priceEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giá (JPY)",
      prop: "priceJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.priceJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceJp", $$v);
      },
      expression: "form.priceJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số ngày hết hạn"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.form.expiresDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiresDay", $$v);
      },
      expression: "form.expiresDay"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giảm giá (%)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.form.discountPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discountPercent", $$v);
      },
      expression: "form.discountPercent"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số ngày miễn phí"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.form.freeDays,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freeDays", $$v);
      },
      expression: "form.freeDays"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Apple Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.appleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "appleId", $$v);
      },
      expression: "form.appleId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Google Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.googleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "googleId", $$v);
      },
      expression: "form.googleId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "PayPal Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.paypalId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paypalId", $$v);
      },
      expression: "form.paypalId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Paypal Foreign Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.paypalForeignId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paypalForeignId", $$v);
      },
      expression: "form.paypalForeignId"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.handleSaveDetail();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;