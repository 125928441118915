"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật thể loại" : "Thêm mới thể loại",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên thể loại",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ứng dụng",
      prop: "mobileAppIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.mobileAppIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mobileAppIds", $$v);
      },
      expression: "form.mobileAppIds"
    }
  }, _vm._l(_vm.mobileApps, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.appName,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Giới hạn vùng truy cập",
      prop: "isLimitRange"
    }
  }, [_c("span", [_vm._v("Không")]), _c("el-switch", {
    staticStyle: {
      margin: "0 8px"
    },
    attrs: {
      "inactive-color": "#13ce66",
      "active-color": "#ff4949"
    },
    model: {
      value: _vm.form.isLimitRange,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isLimitRange", $$v);
      },
      expression: "form.isLimitRange"
    }
  }), _c("span", [_vm._v("Có")])], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;