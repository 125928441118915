"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.quizTestApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var quizTestApi = exports.quizTestApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/quizTest'
    });
  }
  // create: (data): AxiosPromise<any> => request({
  //     url: '/v1/admin/quizTest',
  //     data,
  //     method: 'post'
  // }),
  // update: (id, data): AxiosPromise<any> => request({
  //     url: `/v1/admin/quizTest/${id}`,
  //     method: 'patch',
  //     data
  // }),
  // delete: (id): AxiosPromise<any> => request({
  //     url: `/v1/admin/quizTest/${id}`,
  //     method: 'delete'
  // }),
};