"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SyncPortalRole = exports.RoleName = void 0;
var SyncPortalRole;
(function (SyncPortalRole) {
  SyncPortalRole["QuanTriVien"] = "QUAN_TRI_VIEN";
  SyncPortalRole["ThuThu"] = "THU_THU";
  SyncPortalRole["KTV"] = "KY_THUAT_VIEN";
  SyncPortalRole["HocVien"] = "HOC_VIEN";
  SyncPortalRole["GV"] = "GIANG_VIEN";
  SyncPortalRole["GVCC"] = "GIANG_VIEN_CAP_CAO";
  SyncPortalRole["DaiLy"] = "DAI_LY";
  SyncPortalRole["DaiLy2"] = "DAI_LY_2";
})(SyncPortalRole || (exports.SyncPortalRole = SyncPortalRole = {}));
var RoleName;
(function (RoleName) {
  RoleName["Admin"] = "ADMIN";
  RoleName["Dev"] = "DEV";
  RoleName["Manager"] = "MANAGER";
  RoleName["Advisor"] = "ADVISOR";
  RoleName["Sale"] = "SALE";
  RoleName["Account"] = "ACCOUNT";
  RoleName["Service"] = "SERVICE";
})(RoleName || (exports.RoleName = RoleName = {}));