"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt, email",
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleQuery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("el-select", {
    attrs: {
      size: "medium",
      filterable: "",
      clearable: "",
      placeholder: "Tất cả"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.query.isBlocked,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isBlocked", $$v);
      },
      expression: "query.isBlocked"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Đã kích hoạt",
      value: false
    }
  }), _c("el-option", {
    attrs: {
      label: "Ngừng kích hoạt",
      value: true
    }
  })], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Sắp xếp theo ngày tạo")]), _c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      size: "medium",
      placeholder: "Chọn sắp xếp"
    },
    on: {
      change: _vm.handleSortChange
    },
    model: {
      value: _vm.sort,
      callback: function callback($$v) {
        _vm.sort = $$v;
      },
      expression: "sort"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Tăng dần",
      value: "ASC"
    }
  }), _c("el-option", {
    attrs: {
      label: "Giảm dần",
      value: "DESC"
    }
  })], 1)], 1), _vm._l(_vm.typeMostFilters, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "filter-item"
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(_vm.getTranslatedTypeMost(item.type)))]), _c("el-select", {
      attrs: {
        id: item.type,
        size: "medium",
        filterable: "",
        clearable: ""
      },
      on: {
        change: function change($event) {
          return _vm.handleSelectFilterTypeMostChange();
        }
      },
      model: {
        value: _vm.selectedTypeMost[item.type],
        callback: function callback($$v) {
          _vm.$set(_vm.selectedTypeMost, item.type, $$v);
        },
        expression: "selectedTypeMost[item.type]"
      }
    }, _vm._l(item.data, function (option) {
      return _c("el-option", {
        key: option.value,
        attrs: {
          label: option.label,
          value: option.value
        }
      });
    }), 1)], 1);
  }), _vm._l(_vm.dataFilters, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "filter-item"
    }, [_c("label", {
      attrs: {
        for: ""
      }
    }, [_vm._v(_vm._s(_vm.getTranslatedType(item.type)))]), _c("el-select", {
      attrs: {
        id: item.type,
        size: "medium",
        filterable: "",
        clearable: ""
      },
      on: {
        change: function change($event) {
          return _vm.handleSelectFilterChange();
        }
      },
      model: {
        value: _vm.selected[item.type],
        callback: function callback($$v) {
          _vm.$set(_vm.selected, item.type, $$v);
        },
        expression: "selected[item.type]"
      }
    }, _vm._l(item.data, function (option) {
      return _c("el-option", {
        key: option,
        attrs: {
          label: option,
          value: option
        }
      });
    }), 1)], 1);
  }), _vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v("Thêm người dùng")])], 1) : _vm._e(), _vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleImport
    }
  }, [_vm._v("Thêm người dùng (Excel)")])], 1) : _vm._e(), _vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.showModal
    }
  }, [_vm._v("Xuất file (Excel)")])], 1) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "Nhập số trang",
      visible: _vm.isModalVisible,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isModalVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.pageRange
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Từ trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.from,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "from", _vm._n($$v));
      },
      expression: "pageRange.from"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đến trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.to,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "to", _vm._n($$v));
      },
      expression: "pageRange.to"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.isModalVisible = false;
      }
    }
  }, [_vm._v("Hủy")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitPageRange
    }
  }, [_vm._v("Xác nhận")])], 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-setting"
    },
    on: {
      click: function click($event) {
        _vm.showDrawer = true;
      }
    }
  }, [_vm._v("Cài đặt hiển thị")])], 1)], 2), _c("div", {
    staticStyle: {
      "margin-bottom": "4px"
    }
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Đồng bộ lần cuối: ")]), _c("span", [_vm._v(_vm._s(_vm.lastSyncAt ? _vm.$formatDateTime(_vm.lastSyncAt) : "Chưa đồng bộ"))])]), _c("el-drawer", {
    attrs: {
      size: "400px",
      title: "I am the title",
      visible: _vm.showDrawer,
      "with-header": false,
      "before-close": _vm.closeDrawer
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showDrawer = $event;
      }
    }
  }, [_c("h1", {
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v("Các cột đang được hiển thị")]), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-between",
      height: "calc(100% - 65px)"
    }
  }, [_c("el-checkbox-group", {
    staticStyle: {
      display: "flex",
      "flex-direction": "column",
      gap: "10px",
      "font-size": "20px"
    },
    model: {
      value: _vm.temporaryCheckedColumns,
      callback: function callback($$v) {
        _vm.temporaryCheckedColumns = $$v;
      },
      expression: "temporaryCheckedColumns"
    }
  }, _vm._l(_vm.columns, function (column) {
    return _c("el-checkbox", {
      key: column.prop,
      attrs: {
        label: column.prop
      }
    }, [_c("label", {
      staticStyle: {
        "font-size": "16px"
      }
    }, [_vm._v(" " + _vm._s(column.label))])]);
  }), 1), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveColumnVisibility
    }
  }, [_vm._v("Lưu")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      id: "monitorTable",
      data: _vm.data,
      border: "",
      height: "600px"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "selection"
    }
  }), _vm._l(_vm.checkedColumns, function (column) {
    return _c("el-table-column", {
      key: column.prop,
      attrs: {
        label: _vm.getColumnLabel(column.prop)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var _row$surveyForms, _row$surveyForms2, _row$surveyForms4, _row$surveyForms5, _row$surveyForms6, _row$surveyForms7, _row$surveyForms8, _row$surveyForms9, _row$surveyForms10, _JSON$parse, _row$surveyForms11, _row$surveyForms12, _row$surveyForms13, _JSON$parse2, _row$surveyForms14, _row$surveyForms15, _row$surveyForms16, _row$surveyForms17, _row$surveyForms18, _row$surveyForms19, _row$feedbacks, _row$feedbacks2, _row$feedbacks3, _row$feedbacks4, _row$feedbacks5, _row$feedbacks6, _row$historyBooks, _row$historyBooks2, _row$historyBooks3, _row$conversation, _row$conversation2, _row$conversation3, _JSON$parse4, _JSON$parse5, _JSON$parse7, _JSON$parse8, _row$commentsOfExpert, _row$commentsOfExpert2, _row$commentsOfExpert4, _row$commentsOfExpert5;
          var row = _ref.row;
          return [column.prop === "personalInfo" ? _c("div", [_c("div", {
            staticStyle: {
              "margin-bottom": "10px"
            }
          }, [_c("img", {
            staticStyle: {
              "border-radius": "50%",
              "object-fit": "cover",
              margin: "0 auto",
              display: "block"
            },
            attrs: {
              width: "60",
              height: "60",
              src: row.avatar ? _vm.$url.image(row.avatar) : _vm.avatarDefault,
              alt: ""
            }
          })]), row !== null && row !== void 0 && row.name ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Họ tên: ")]), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.name))])]) : _vm._e(), row !== null && row !== void 0 && row.username ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row === null || row === void 0 ? void 0 : row.username))])]) : _vm._e(), row.phone ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Số điện thoại: ")]), _c("span", [_vm._v(_vm._s(row.phone))])]) : _vm._e(), row.email ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Email: ")]), _c("span", [_vm._v(_vm._s(row.email))])]) : _vm._e(), _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Nguồn: ")]), _c("span", [_vm._v(_vm._s(row.createFrom == _vm.CreateFrom.Self ? "Portal" : "Sync"))])]), row.azureId ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Azure Id: ")]), _c("span", [_vm._v(" " + _vm._s(row.azureId) + " ")])]) : _vm._e(), row.azureEmail ? _c("div", [_c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Azure email: ")]), _c("span", [_vm._v(_vm._s(row.azureEmail))])]) : _vm._e(), _c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Giới tính: ")]), row.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Ngày sinh: ")]), row.dob ? _c("span", [_vm._v(_vm._s(row.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Zalo: ")]), row.zalo ? _c("span", [_vm._v(_vm._s(row.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
            attrs: {
              for: ""
            }
          }, [_vm._v("Facebook: ")]), row.facebook ? _c("span", [_vm._v(_vm._s(row.facebook))]) : _c("span", [_vm._v("--")]), _c("div", {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "center",
              "align-items": "start"
            }
          }, [_vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("el-button", {
            staticStyle: {
              display: "block",
              "margin-left": "0",
              "margin-top": "10px"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.openBoughtBook(row);
              }
            }
          }, [_vm._v("Bài tập đã mua")]) : _vm._e(), _vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("el-button", {
            staticStyle: {
              display: "block",
              "margin-left": "0",
              "margin-top": "10px"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.openHistoryBoughtBook(row);
              }
            }
          }, [_vm._v("Lịch sử bài tập đã mua")]) : _vm._e()], 1)]) : _vm._e(), column.prop === "healthSurveyData" ? _c("div", [((_row$surveyForms = row.surveyForms) === null || _row$surveyForms === void 0 ? void 0 : _row$surveyForms.length) > 0 ? _c("div", _vm._l(JSON.parse(row.surveyForms[((_row$surveyForms2 = row.surveyForms) === null || _row$surveyForms2 === void 0 ? void 0 : _row$surveyForms2.length) - 1].jsonDataGeneral), function (item) {
            var _row$surveyForms3;
            return _vm.isValidJSON(row.surveyForms[((_row$surveyForms3 = row.surveyForms) === null || _row$surveyForms3 === void 0 ? void 0 : _row$surveyForms3.length) - 1].jsonDataGeneral) ? _c("div", [_vm.shouldBeIncluded(item) ? [_c("label", [_vm._v(_vm._s(item.question) + ": ")]), _c("span", [_vm._l(item.quizChoices, function (quizChoice, index) {
              return [item.type === "multiple-choice" ? [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(quizChoice.choice)), _c("br")] : [_vm._v(" " + _vm._s(quizChoice.choice)), _c("br")]];
            })], 2)] : _vm._e()], 2) : _vm._e();
          }), 0) : _c("div", [_vm._v("Chưa có dữ liệu khảo sát")]), ((_row$surveyForms4 = row.surveyForms) === null || _row$surveyForms4 === void 0 ? void 0 : _row$surveyForms4.length) > 0 ? _c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-badge", {
            staticClass: "item",
            attrs: {
              hidden: ((_row$surveyForms5 = row.surveyForms) === null || _row$surveyForms5 === void 0 ? void 0 : _row$surveyForms5.length) > 0 && ((_row$surveyForms6 = row.surveyForms) === null || _row$surveyForms6 === void 0 ? void 0 : _row$surveyForms6.length) - 1 == 0,
              value: ((_row$surveyForms7 = row.surveyForms) === null || _row$surveyForms7 === void 0 ? void 0 : _row$surveyForms7.length) - 1
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.handleViewSurveyForm(row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)], 1) : _vm._e()]) : _vm._e(), column.prop === "aiRoadmap" ? _c("div", [((_row$surveyForms8 = row.surveyForms) === null || _row$surveyForms8 === void 0 ? void 0 : _row$surveyForms8.length) > 0 && (_row$surveyForms9 = row.surveyForms[((_row$surveyForms10 = row.surveyForms) === null || _row$surveyForms10 === void 0 ? void 0 : _row$surveyForms10.length) - 1]) !== null && _row$surveyForms9 !== void 0 && _row$surveyForms9.dataAIJson ? _c("div", [_c("div", [_c("label", [_vm._v("Tổng thời gian tập:")]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.calculateTotalProgress((_JSON$parse = JSON.parse(row.surveyForms[((_row$surveyForms11 = row.surveyForms) === null || _row$surveyForms11 === void 0 ? void 0 : _row$surveyForms11.length) - 1].dataAIJson)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.roadmap)) + " phút")])])]), (_row$surveyForms12 = row.surveyForms[((_row$surveyForms13 = row.surveyForms) === null || _row$surveyForms13 === void 0 ? void 0 : _row$surveyForms13.length) - 1]) !== null && _row$surveyForms12 !== void 0 && _row$surveyForms12.dataAIJson ? _c("div", [_vm._l((_JSON$parse2 = JSON.parse((_row$surveyForms14 = row.surveyForms[((_row$surveyForms15 = row.surveyForms) === null || _row$surveyForms15 === void 0 ? void 0 : _row$surveyForms15.length) - 1]) === null || _row$surveyForms14 === void 0 ? void 0 : _row$surveyForms14.dataAIJson)) === null || _JSON$parse2 === void 0 || (_JSON$parse2 = _JSON$parse2.roadmap) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.slice(0, 3), function (week, index) {
            var _vm$getUniqueBooks;
            return [_c("div", {
              key: index
            }, [((_vm$getUniqueBooks = _vm.getUniqueBooks(week.dayOfWeek)) === null || _vm$getUniqueBooks === void 0 ? void 0 : _vm$getUniqueBooks.length) > 0 ? _c("div", [_c("label", [_vm._v("Tuần " + _vm._s(week.week) + ":")]), _vm._l(_vm.getUniqueBooks(week.dayOfWeek), function (book) {
              return _c("div", [book !== null && book !== void 0 && book.name ? _c("span", [_vm._v(" " + _vm._s(book === null || book === void 0 ? void 0 : book.name))]) : _vm._e()]);
            })], 2) : _vm._e()])];
          })], 2) : _vm._e()]) : _c("div", [_vm._v("Chưa có lộ trình")]), ((_row$surveyForms16 = row.surveyForms) === null || _row$surveyForms16 === void 0 ? void 0 : _row$surveyForms16.length) > 0 ? _c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-badge", {
            staticClass: "item",
            attrs: {
              hidden: ((_row$surveyForms17 = row.surveyForms) === null || _row$surveyForms17 === void 0 ? void 0 : _row$surveyForms17.length) > 0 && ((_row$surveyForms18 = row.surveyForms) === null || _row$surveyForms18 === void 0 ? void 0 : _row$surveyForms18.length) - 1 == 0,
              value: ((_row$surveyForms19 = row.surveyForms) === null || _row$surveyForms19 === void 0 ? void 0 : _row$surveyForms19.length) - 1
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.handleViewAiRoadmap(row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)], 1) : _vm._e()]) : _vm._e(), column.prop === "appFeedbackSurvey" ? _c("div", [((_row$feedbacks = row.feedbacks) === null || _row$feedbacks === void 0 ? void 0 : _row$feedbacks.length) > 0 ? _c("div", _vm._l(row.feedbacks[((_row$feedbacks2 = row.feedbacks) === null || _row$feedbacks2 === void 0 ? void 0 : _row$feedbacks2.length) - 1].jsonFeedback, function (item) {
            var _JSON$parse3;
            return _vm.isValidJSON(item) ? _c("div", _vm._l((_JSON$parse3 = JSON.parse(item)) === null || _JSON$parse3 === void 0 ? void 0 : _JSON$parse3.slice(0, 3), function (itemJson) {
              return _c("div", [_c("label", [_vm._v(_vm._s(itemJson.question))]), _c("span", [_vm._l(itemJson.quizChoices, function (quizChoice, index) {
                return [quizChoice.choice === "Tải ảnh lên" ? [_c("div", {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    gap: "5px",
                    "flex-wrap": "wrap",
                    "margin-bottom": "10px"
                  }
                }, _vm._l(quizChoice.imageChoice, function (img) {
                  return _c("div", {
                    key: img
                  }, [_c("el-image", {
                    staticStyle: {
                      height: "100px"
                    },
                    attrs: {
                      src: _vm.$url.image(img),
                      alt: "",
                      "preview-src-list": [_vm.$url.image(img)]
                    }
                  })], 1);
                }), 0)] : itemJson.type === "multiple-choice" ? [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(quizChoice.choice ? "Nội dung phản hồi:" : "Hình ảnh:") + " " + _vm._s(quizChoice.choice)), _c("br"), _c("div", {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    gap: "5px",
                    "flex-wrap": "wrap",
                    "margin-bottom": "10px"
                  }
                }, _vm._l(quizChoice.imageChoice, function (img) {
                  return quizChoice.imageChoice ? _c("div", {
                    key: img
                  }, [_c("el-image", {
                    staticStyle: {
                      height: "100px"
                    },
                    attrs: {
                      src: _vm.$url.image(img),
                      alt: "",
                      "preview-src-list": [_vm.$url.image(img)]
                    }
                  })], 1) : _vm._e();
                }), 0)] : [_vm._v(" " + _vm._s(quizChoice.choice)), _c("br")]];
              })], 2)]);
            }), 0) : _vm._e();
          }), 0) : _c("div", [_vm._v("Chưa có khảo sát")]), ((_row$feedbacks3 = row.feedbacks) === null || _row$feedbacks3 === void 0 ? void 0 : _row$feedbacks3.length) > 0 ? _c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-badge", {
            staticClass: "item",
            attrs: {
              hidden: ((_row$feedbacks4 = row.feedbacks) === null || _row$feedbacks4 === void 0 ? void 0 : _row$feedbacks4.length) > 0 && ((_row$feedbacks5 = row.feedbacks) === null || _row$feedbacks5 === void 0 ? void 0 : _row$feedbacks5.length) - 1 == 0,
              value: ((_row$feedbacks6 = row.feedbacks) === null || _row$feedbacks6 === void 0 ? void 0 : _row$feedbacks6.length) - 1
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.handleViewFeedbackSurvey(row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)], 1) : _vm._e()]) : _vm._e(), column.prop === "trainingHistory" ? _c("div", [((_row$historyBooks = row.historyBooks) === null || _row$historyBooks === void 0 ? void 0 : _row$historyBooks.length) > 0 ? _c("div", [_vm._l((_row$historyBooks2 = row.historyBooks) === null || _row$historyBooks2 === void 0 ? void 0 : _row$historyBooks2.slice(0, 3), function (item) {
            var _item$book, _item$book2;
            return _c("div", [_c("div", [(_item$book = item.book) !== null && _item$book !== void 0 && _item$book.name ? _c("label", [_vm._v(_vm._s((_item$book2 = item.book) === null || _item$book2 === void 0 ? void 0 : _item$book2.name))]) : _vm._e(), _c("div", [_c("label", [_vm._v("Lần tập: ")]), _c("span", [_vm._v(_vm._s(item.count) + " ")])]), _c("div", [_c("label", [_vm._v("Điểm AI: ")]), _c("span", [_vm._v("--")])]), _c("div", [_c("label", [_vm._v("Lịch sử keypoints: ")]), _c("span", [_vm._v("--")])])]), _c("el-divider")], 1);
          }), _c("div", [_c("label", [_vm._v("Thời gian luyện tập: ")]), row.totalPracticeTime ? _c("span", [_vm._v(_vm._s(_vm.secondsToHms(row.totalPracticeTime)))]) : _c("span", [_vm._v("--")])])], 2) : _c("div", [_vm._v("Chưa có lịch sử tập luyện")]), ((_row$historyBooks3 = row.historyBooks) === null || _row$historyBooks3 === void 0 ? void 0 : _row$historyBooks3.length) > 0 ? _c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.openHistoryBook(row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1) : _vm._e()]) : _vm._e(), column.prop === "activationStatus" ? _c("div", [!row.isBlocked ? [_c("el-tag", {
            attrs: {
              type: "success",
              effect: "dark"
            }
          }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.expiredAt)))]) : _vm._e()] : _c("el-tag", {
            attrs: {
              type: "danger",
              effect: "dark"
            }
          }, [_vm._v(" Chưa kích hoạt ")])], 2) : _vm._e(), column.prop === "chat" ? _c("div", {
            staticStyle: {
              width: "300"
            }
          }, [!row.isBlocked ? [_c("div", [(((_row$conversation = row.conversation) === null || _row$conversation === void 0 ? void 0 : _row$conversation.length) !== 0 ? true : false) ? [_vm._l((_row$conversation2 = row.conversation) === null || _row$conversation2 === void 0 ? void 0 : _row$conversation2.slice(-2), function (item, index) {
            return _c("div", {
              key: index
            }, [_c("span", {
              staticStyle: {
                "font-weight": "700"
              }
            }, [_vm._v(" " + _vm._s(item.factor == "BOT" ? "Bot" : "Người dùng") + " " + _vm._s("(".concat(_vm.$formatDateTime(item.createdAt), ")")) + ": ")]), _c("span", {
              staticClass: "chat-content"
            }, [_vm._v(" " + _vm._s(item.content))])]);
          }), _c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("div", {
            staticStyle: {
              "margin-top": "10px"
            }
          }, [_c("el-button", {
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                $event.stopPropagation();
                return _vm.openChatHistoryModal(row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)])] : _vm._e(), ((_row$conversation3 = row.conversation) === null || _row$conversation3 === void 0 ? void 0 : _row$conversation3.length) === 0 ? _c("div", [_c("span", [_vm._v("Chưa có cuộc trò chuyện")])]) : _vm._e()], 2)] : _vm._e()], 2) : _vm._e(), column.prop === "review1" ? _c("div", [!row.isBlocked ? [_c("div", [row.reviewJson && Array.isArray(JSON.parse(row.reviewJson)) && ((_JSON$parse4 = JSON.parse(row.reviewJson)) === null || _JSON$parse4 === void 0 ? void 0 : _JSON$parse4.length) !== 0 ? _vm._l((_JSON$parse5 = JSON.parse(row.reviewJson)) === null || _JSON$parse5 === void 0 ? void 0 : _JSON$parse5.slice(-3), function (item, index) {
            var _JSON$parse6;
            return _c("div", {
              key: index
            }, [item.expert.name ? _c("span", [_c("span", {
              style: {
                fontWeight: 700
              }
            }, [_vm._v("Người đánh giá:")]), _vm._v(" " + _vm._s(item.expert.name))]) : _vm._e(), _c("br"), _c("span", [_c("span", {
              style: {
                fontWeight: 700
              }
            }, [_vm._v("Tiêu đề:")]), _vm._v(" " + _vm._s(item.title))]), _c("br"), _c("span", [_c("span", {
              style: {
                fontWeight: 700
              }
            }, [_vm._v("Nội dung:")]), _vm._v(" " + _vm._s(item.content))]), _c("br"), index != ((_JSON$parse6 = JSON.parse(row.reviewJson)) === null || _JSON$parse6 === void 0 ? void 0 : _JSON$parse6.length) - 1 ? _c("el-divider") : _vm._e()], 1);
          }) : _vm._e(), _c("div", [!_vm.findCurrentUserReview(JSON.parse(row.reviewJson)) ? _c("div", {
            staticStyle: {
              "margin-bottom": "10px"
            }
          }, [_vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("el-button", {
            staticStyle: {
              display: "block",
              width: "110px"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-edit"
            },
            on: {
              click: function click($event) {
                _vm.openReview1Modal(JSON.parse(row.reviewJson), "create", row);
              }
            }
          }, [_vm._v(_vm._s("Đánh giá"))]) : _vm._e()], 1) : _vm._e(), row.reviewJson && ((_JSON$parse7 = JSON.parse(row.reviewJson)) === null || _JSON$parse7 === void 0 ? void 0 : _JSON$parse7.length) > 0 ? _c("div", [_c("el-badge", {
            staticClass: "item",
            attrs: {
              value: row.reviewJson && ((_JSON$parse8 = JSON.parse(row.reviewJson)) === null || _JSON$parse8 === void 0 ? void 0 : _JSON$parse8.length) - 1
            }
          }, [_c("el-button", {
            staticStyle: {
              display: "block"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                _vm.openReviewTableModal1(JSON.parse(row.reviewJson), row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)], 1) : _vm._e()])], 2)] : _c("el-tag", {
            attrs: {
              type: "danger",
              effect: "dark"
            }
          }, [_vm._v(" Chưa kích hoạt ")])], 2) : _vm._e(), column.prop === "review" ? _c("div", [!row.isBlocked ? [_c("div", [(((_row$commentsOfExpert = row.commentsOfExpert) === null || _row$commentsOfExpert === void 0 ? void 0 : _row$commentsOfExpert.length) !== 0 ? true : false) ? _vm._l((_row$commentsOfExpert2 = row.commentsOfExpert) === null || _row$commentsOfExpert2 === void 0 ? void 0 : _row$commentsOfExpert2.slice(-3), function (item, index) {
            var _row$commentsOfExpert3;
            return _c("div", {
              key: index
            }, [_c("span", [_c("span", {
              style: {
                fontWeight: 700
              }
            }, [_vm._v("Người ghi chú:")]), _vm._v(" " + _vm._s(item.expert.name))]), _c("br"), _c("span", [_c("span", {
              style: {
                fontWeight: 700
              }
            }, [_vm._v("Nội dung:")]), _vm._v(" " + _vm._s(item.comment))]), _c("br"), item.expert.id == _vm.$store.state.user.info.id ? _c("div", {
              staticStyle: {
                "margin-bottom": "10px"
              }
            }, [_c("el-button", {
              staticStyle: {
                display: "block"
              },
              attrs: {
                type: "primary",
                size: "mini",
                icon: "el-icon-edit"
              },
              on: {
                click: function click($event) {
                  _vm.openReviewModal(row.commentsOfExpert, "update", row, _vm.findCurrentUserComment(row.commentsOfExpert));
                }
              }
            }, [_vm._v(_vm._s("Cập nhật"))])], 1) : _vm._e(), index != ((_row$commentsOfExpert3 = row.commentsOfExpert) === null || _row$commentsOfExpert3 === void 0 || (_row$commentsOfExpert3 = _row$commentsOfExpert3.slice(-3)) === null || _row$commentsOfExpert3 === void 0 ? void 0 : _row$commentsOfExpert3.length) - 1 ? _c("el-divider") : _vm._e()], 1);
          }) : _vm._e(), _c("div", [!_vm.findCurrentUserComment(row.commentsOfExpert) ? _c("div", {
            staticStyle: {
              "margin-bottom": "10px"
            }
          }, [_vm.info.role.type !== _vm.SyncPortalRole.DaiLy ? _c("el-button", {
            staticStyle: {
              display: "block"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-edit-outline"
            },
            on: {
              click: function click($event) {
                return _vm.openReviewModal(row.commentsOfExpert, "create", row);
              }
            }
          }, [_vm._v(_vm._s("Ghi chú"))]) : _vm._e()], 1) : _vm._e(), ((_row$commentsOfExpert4 = row.commentsOfExpert) === null || _row$commentsOfExpert4 === void 0 ? void 0 : _row$commentsOfExpert4.length) > 3 ? _c("div", [_c("el-badge", {
            staticClass: "item",
            attrs: {
              value: ((_row$commentsOfExpert5 = row.commentsOfExpert) === null || _row$commentsOfExpert5 === void 0 ? void 0 : _row$commentsOfExpert5.length) - 3
            }
          }, [_c("el-button", {
            staticStyle: {
              display: "block"
            },
            attrs: {
              type: "primary",
              size: "mini",
              icon: "el-icon-view"
            },
            on: {
              click: function click($event) {
                return _vm.openReviewTableModal(row.commentsOfExpert, row);
              }
            }
          }, [_vm._v("Xem thêm")])], 1)], 1) : _vm._e()])], 2)] : _c("el-tag", {
            attrs: {
              type: "danger",
              effect: "dark"
            }
          }, [_vm._v("Chưa kích hoạt ")])], 2) : _vm._e(), column.prop === "createdAt" ? _c("div", [[_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")]], 2) : _vm._e()];
        }
      }], null, true)
    });
  })], 2), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit,
      pageSizes: [10, 20, 30, 50, 100]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("staff-modal", {
    ref: "StaffModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("SurveyFormModal", {
    ref: "SurveyFormModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("AiRoadmapModal", {
    ref: "AiRoadmapModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("FeedbackSurveyModal", {
    ref: "FeedbackSurveyModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("RoadmapDetailModal", {
    ref: "RoadmapDetailModal",
    on: {
      "submit:success": _vm.fetchData
    }
  }), _c("ActiveAccountModal", {
    ref: "ActiveAccountModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ImportStaffModal", {
    ref: "ImportStaffModal",
    on: {
      "submit:ok": _vm.fetchData
    }
  }), _c("ReadBookHistoryModal", {
    ref: "ReadBookHistoryModal"
  }), _c("ManageBoughtBookModal", {
    ref: "ManageBoughtBookModal"
  }), _c("HistoryBoughtBookModal", {
    ref: "HistoryBoughtBookModal"
  }), _c("ImportClassroomModal", {
    ref: "ImportClassroomModal"
  }), _c("ReviewModal", {
    ref: "ReviewModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ReviewModal1", {
    ref: "ReviewModal1",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ReviewTableModal", {
    ref: "ReviewTableModal",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ReviewTableModal1", {
    ref: "ReviewTableModal1",
    on: {
      submitOk: _vm.fetchData
    }
  }), _c("ChatHistoryModal", {
    ref: "ChatHistoryModal"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;