"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.messageEventTypeSelected !== _vm.MessageEventType.WhenDonePracticeAI && _vm.messageEventTypeSelected !== _vm.MessageEventType.WhenDonePracticeVideo ? _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (375x812)",
      prop: "image"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.messageEvent.image,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": _vm.handleUploadSuccess
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.messageEvent.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form.messageEvent, "content", $$v);
      },
      expression: "form.messageEvent.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.messageEvent.contentEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form.messageEvent, "contentEn", $$v);
      },
      expression: "form.messageEvent.contentEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Lời nhắc (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.messageEvent.contentJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form.messageEvent, "contentJp", $$v);
      },
      expression: "form.messageEvent.contentJp"
    }
  })], 1)], 1) : _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (375x812)",
      prop: "image"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.messageEvent.image,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": _vm.handleUploadSuccess
    }
  })], 1), _c("div", {
    staticClass: "reminder-section"
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 50% (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.vi.Type50.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.vi.Type50, "content", $$v);
      },
      expression: "reminders.vi.Type50.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 70% (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.vi.Type70.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.vi.Type70, "content", $$v);
      },
      expression: "reminders.vi.Type70.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 100% (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.vi.Type100.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.vi.Type100, "content", $$v);
      },
      expression: "reminders.vi.Type100.content"
    }
  })], 1)], 1), _c("div", {
    staticClass: "reminder-section"
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 50% (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.en.Type50.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.en.Type50, "content", $$v);
      },
      expression: "reminders.en.Type50.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 70% (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.en.Type70.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.en.Type70, "content", $$v);
      },
      expression: "reminders.en.Type70.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 100% (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.en.Type100.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.en.Type100, "content", $$v);
      },
      expression: "reminders.en.Type100.content"
    }
  })], 1)], 1), _c("div", {
    staticClass: "reminder-section"
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 50% (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.jp.Type50.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.jp.Type50, "content", $$v);
      },
      expression: "reminders.jp.Type50.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 70% (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.jp.Type70.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.jp.Type70, "content", $$v);
      },
      expression: "reminders.jp.Type70.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiến độ 100% (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.reminders.jp.Type100.content,
      callback: function callback($$v) {
        _vm.$set(_vm.reminders.jp.Type100, "content", $$v);
      },
      expression: "reminders.jp.Type100.content"
    }
  })], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;