"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var authApi = exports.authApi = {
  getVisEduToken: function getVisEduToken(data) {
    return (0, _request.default)({
      baseURL: 'https://visedu.vn:12501',
      url: '/api/v1/authentication/login',
      method: 'post',
      data: data
    });
  },
  login: function login(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/login',
      data: data,
      method: 'post'
    });
  },
  loginAzure: function loginAzure(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/login/admin/azure',
      data: data,
      method: 'post'
    });
  },
  verifyPassword: function verifyPassword(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/password/verify',
      data: data,
      method: 'post'
    });
  },
  profile: function profile() {
    return (0, _request.default)({
      url: '/v1/admin/auth/profile'
    });
  },
  updatePassword: function updatePassword(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/password/update',
      method: 'post',
      data: data
    });
  },
  verifyOtp: function verifyOtp(data) {
    return (0, _request.default)({
      url: '/v1/admin/auth/otp',
      method: 'post',
      data: data
    });
  },
  reset: function reset(data) {
    return (0, _request.default)({
      url: '/auth/password/reset',
      data: data,
      method: 'post'
    });
  },
  forgot: function forgot(data) {
    return (0, _request.default)({
      url: '/auth/password/forgot',
      data: data,
      method: 'post'
    });
  }
};