"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var bannerApi = exports.bannerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/v1/admin/banner',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/v1/admin/banner',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/banner/".concat(id),
      method: 'patch',
      data: data
    });
  },
  visible: function visible(id) {
    return (0, _request.default)({
      url: "/v1/admin/banner/".concat(id, "/visible"),
      method: 'patch'
    });
  },
  invisible: function invisible(id) {
    return (0, _request.default)({
      url: "/v1/admin/banner/".concat(id, "/invisible"),
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/banner/".concat(id),
      method: 'delete'
    });
  }
};