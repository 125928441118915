"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boughtBookApi = void 0;
var _request = require("@/utils/request");
var boughtBookApi = exports.boughtBookApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/admin',
      params: params
    });
  },
  findAllHistory: function findAllHistory(params) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/admin/history',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user',
      data: data,
      method: 'post'
    });
  },
  update: function update(data) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/update',
      data: data,
      method: 'post'
    });
  },
  updateHistory: function updateHistory(data) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/update/history',
      data: data,
      method: 'post'
    });
  },
  delete: function _delete(data) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/soft-delete',
      data: data,
      method: 'delete'
    });
  },
  deleteHistory: function deleteHistory(data) {
    return (0, _request.request)({
      url: '/v1/admin/book-bought-by-user/soft-delete/history',
      data: data,
      method: 'delete'
    });
  }
};