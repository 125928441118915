"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "recommend-book-modal",
    attrs: {
      title: _vm.status == "update" ? "Cập nhật ghi chú" : "Ghi chú",
      visible: _vm.visible,
      width: "800px",
      top: "30px",
      "append-to-body": "",
      "close-on-click-modal": true
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ghi chú"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Nội dung ghi chú",
      type: "textarea",
      row: {
        min: 3
      }
    },
    model: {
      value: _vm.form.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.status != "view" ? _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status != "view" && _vm.createData();
      }
    }
  }, [_vm._v("Lưu")]) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;