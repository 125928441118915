"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Lịch sử đọc",
      visible: _vm.visible,
      width: "1200px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Sách"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("img", {
          staticStyle: {
            "border-radius": "5px",
            width: "40px"
          },
          attrs: {
            src: _vm.$url.image(row.thumbnail),
            alt: "",
            width: 80
          }
        }), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thể loại",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tác giả",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s(row.authors.map(function (e) {
          return e.name;
        }).join(", ")))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Chương"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.bookChapters.length ? _c("span", [_vm._v(" " + _vm._s(row.bookChapters.length) + " chương ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lần đọc",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.historyBooks.length ? _c("span", [_vm._v(" " + _vm._s(row.historyBooks[0].count) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đọc lần cuối"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.historyBooks.length ? _c("span", [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.historyBooks[0].updatedAt)) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit,
      pageSizes: [10, 20, 30, 50, 100]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;