"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguageTrans = exports.Language = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var Language;
(function (Language) {
  Language["Vi"] = "VI";
  Language["En"] = "EN";
  Language["JP"] = "JP";
})(Language || (exports.Language = Language = {}));
var LanguageTrans = exports.LanguageTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, Language.Vi, "Tiếng Việt"), Language.En, "Tiếng Anh"), Language.JP, "Tiếng Nhật");