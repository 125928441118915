"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configReminderTypeTrans = exports.ConfigReminderType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ConfigReminderType;
(function (ConfigReminderType) {
  ConfigReminderType["Special"] = "SPECIAL";
  ConfigReminderType["Normal"] = "NORMAL";
})(ConfigReminderType || (exports.ConfigReminderType = ConfigReminderType = {}));
var configReminderTypeTrans = exports.configReminderTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, ConfigReminderType.Special, "Bạn có một việc khẩn, cần làm ngay!"), ConfigReminderType.Normal, "Bạn có một lời nhắc");