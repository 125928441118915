"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configReminderApi = void 0;
var _request = require("@/utils/request");
var configReminderApi = exports.configReminderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: "/v1/admin/configReminder",
      params: params
    });
  },
  findByType: function findByType(type) {
    return (0, _request.request)({
      url: "/v1/admin/configReminder/type",
      params: {
        type: type
      }
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: "/v1/admin/configReminder",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/v1/admin/configReminder/".concat(id),
      method: "patch",
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/configReminder/".concat(id),
      method: "delete"
    });
  }
};