"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var orderApi = exports.orderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/v1/admin/order",
      params: params
    });
  },
  summary: function summary(params) {
    return (0, _request.default)({
      url: "/v1/admin/order/summary",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/v1/admin/order",
      data: data,
      method: "post"
    });
  },
  createTest: function createTest(data) {
    return (0, _request.default)({
      url: "/v1/admin/order/test",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id),
      method: "patch",
      data: data
    });
  },
  updateStatus: function updateStatus(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/status"),
      method: "patch",
      data: data
    });
  },
  assignRef: function assignRef(id, data) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/assignRef"),
      method: "patch",
      data: data
    });
  },
  visible: function visible(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/visible"),
      method: "patch"
    });
  },
  invisible: function invisible(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id, "/invisible"),
      method: "patch"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/".concat(id),
      method: "delete"
    });
  },
  deleteTest: function deleteTest(id) {
    return (0, _request.default)({
      url: "/v1/admin/order/test/".concat(id),
      method: "delete"
    });
  }
};