"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffTypeTrans = exports.StaffType = exports.GenderTrans = exports.Gender = exports.CreateFrom = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var StaffType;
(function (StaffType) {
  StaffType["Customer"] = "CUSTOMER";
  StaffType["Teacher"] = "TEACHER";
  StaffType["Staff"] = "STAFF";
  StaffType["Librarian"] = "LIBRARIAN";
  StaffType["Technician"] = "TECHNICIAN";
  StaffType["TeacherAv"] = "TEACHER_AV";
})(StaffType || (exports.StaffType = StaffType = {}));
var StaffTypeTrans = exports.StaffTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, StaffType.Customer, "Học viên"), StaffType.Teacher, "Giảng viên"), StaffType.Staff, "Quản trị viên"), StaffType.Librarian, "Thủ thư"), StaffType.Technician, "Kỹ thuật viên"), StaffType.TeacherAv, "Giảng viên cấp cao");
var Gender;
(function (Gender) {
  Gender["Male"] = "MALE";
  Gender["FeMale"] = "FE_MALE";
  Gender["Other"] = "OTHER";
})(Gender || (exports.Gender = Gender = {}));
var GenderTrans = exports.GenderTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, Gender.Male, "Nam"), Gender.FeMale, "Nữ"), Gender.Other, "Khác");
var CreateFrom;
(function (CreateFrom) {
  CreateFrom["Self"] = "SELF";
  CreateFrom["Other"] = "OTHER";
})(CreateFrom || (exports.CreateFrom = CreateFrom = {}));