"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm._m(0), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "File name",
      prop: "item"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-button", {
          attrs: {
            type: "primary",
            icon: "el-icon-search",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewDetail(row);
            }
          }
        }, [_vm._v("Xem")])];
      }
    }])
  })], 1), _c("DetailLogModal", {
    ref: "DetailLogModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "filter-container"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Danh sách file log Yoga")])]);
}];
render._withStripped = true;