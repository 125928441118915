"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reviewApi = void 0;
var _request = require("@/utils/request");
var reviewApi = exports.reviewApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: "/v1/admin/review",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: "/v1/admin/review",
      data: data,
      method: "post"
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/v1/admin/review/".concat(id),
      method: "delete"
    });
  }
};