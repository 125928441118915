"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BannerTypeTrans = exports.BannerType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BannerType;
(function (BannerType) {
  BannerType["Book"] = "BOOK";
  BannerType["BuyPackage"] = "BUY_PACKAGE";
})(BannerType || (exports.BannerType = BannerType = {}));
var BannerTypeTrans = exports.BannerTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, BannerType.Book, "Sách"), BannerType.BuyPackage, "Mua gói tập");