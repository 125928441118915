"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật video" : "Thêm mới video",
      "append-to-body": "",
      visible: _vm.visible,
      width: "700px",
      close: _vm.closeModal,
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingForm,
      expression: "loadingForm"
    }],
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại video"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: "",
      filterable: "",
      clearable: "",
      placeholder: "Tất cả"
    },
    model: {
      value: _vm.form.functionType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "functionType", $$v);
      },
      expression: "form.functionType"
    }
  }, _vm._l(_vm.FunctionTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Video"
    }
  }, [_vm.visibleMediaLink ? [_c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "vi");
      },
      "file-list": _vm.videoFileList,
      "on-remove": _vm.handleRemoveVideoGuide
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Việt")])], 1), _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "en");
      },
      "file-list": _vm.videoFileListEn,
      "on-remove": _vm.handleRemoveVideoGuideEn
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Anh")])], 1), _c("el-upload", {
    attrs: {
      headers: {
        token: _vm.token
      },
      action: "".concat(_vm.$uploadDomain).concat(_vm.$uploadPath),
      limit: 1,
      "on-success": function onSuccess(res, file, fileList) {
        return _vm.handleUploadVideo(res, file, fileList, "jp");
      },
      "file-list": _vm.videoFileListJp,
      "on-remove": _vm.handleRemoveVideoGuideJp
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v("Upload video Tiếng Nhật")])], 1)] : _c("span", [_vm._v(" Không có quyền ")])], 2)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;