"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.values.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm")]), _c("el-input", {
    attrs: {
      size: "medium",
      placeholder: "Tìm kiếm theo mã code"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.fetchRequestGift.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái")]), _c("el-select", {
    attrs: {
      size: "medium"
    },
    on: {
      change: _vm.fetchRequestGift
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(Object.values(_vm.requestGiftStatusTrans), function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.fetchRequestGift
    }
  }, [_vm._v("Tìm kiếm")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.showModal
    }
  }, [_vm._v("Xuất file (Excel)")])], 1), _c("el-dialog", {
    attrs: {
      title: "Nhập số trang",
      visible: _vm.isModalVisible,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isModalVisible = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.pageRange
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Từ trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.from,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "from", _vm._n($$v));
      },
      expression: "pageRange.from"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đến trang"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      min: "1"
    },
    model: {
      value: _vm.pageRange.to,
      callback: function callback($$v) {
        _vm.$set(_vm.pageRange, "to", _vm._n($$v));
      },
      expression: "pageRange.to"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.isModalVisible = false;
      }
    }
  }, [_vm._v("Hủy")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitPageRange
    }
  }, [_vm._v("Xác nhận")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.requestGifts,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "index"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Mã code",
      align: "center",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s(row.code))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Tên quà tặng",
      align: "center",
      width: "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _row$gift;
        var row = _ref2.row;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            gap: "4px"
          }
        }, [_c("img", {
          staticStyle: {
            "border-radius": "5px",
            width: "40px"
          },
          attrs: {
            src: _vm.$url.image(row.gift.image),
            alt: ""
          }
        }), _c("span", [_vm._v(_vm._s(row === null || row === void 0 || (_row$gift = row.gift) === null || _row$gift === void 0 ? void 0 : _row$gift.name))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Người đổi quà"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("div", [row.receiverName ? _c("span", [_vm._v(_vm._s(row.receiverName))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c("div", [row.phone ? _c("span", [_vm._v(_vm._s(row.phone))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Địa chỉ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", [row.address ? _c("span", [_vm._v(_vm._s(row.address))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá trị quà"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("div", [row.amount ? _c("span", [_vm._v(_vm._s(_vm.formatCurrency(row.amount)))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số điểm đổi quà"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("div", [row.point ? _c("span", [_vm._v(_vm._s(_vm.formatNumberVN(row.point)))]) : _c("label", [_vm._v("Chưa cập nhật")])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái",
      align: "center",
      width: "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("el-dropdown", {
          attrs: {
            trigger: "click"
          },
          on: {
            command: function command(newStatus) {
              return _vm.handleStatusChange(row, newStatus);
            }
          }
        }, [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Ấn vào để cập nhật trạng thái",
            placement: "top"
          }
        }, [_c("span", {
          staticClass: "status-dropdown-label"
        }, [_c("el-tag", {
          staticStyle: {
            width: "100%"
          },
          attrs: {
            type: _vm.requestGiftStatusTrans[row.status].color
          }
        }, [_vm._v(" " + _vm._s(_vm.requestGiftStatusTrans[row.status].label) + " ")])], 1)]), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, _vm._l(_vm.requestGiftStatusTrans, function (status, key) {
          return status.value !== _vm.RequestGiftStatus.Pending ? _c("el-dropdown-item", {
            key: key,
            attrs: {
              command: status.value
            }
          }, [_c("span", {
            staticClass: "status-item"
          }, [_c("el-tag", {
            staticStyle: {
              width: "100%",
              "text-align": "center"
            },
            attrs: {
              type: _vm.requestGiftStatusTrans[status.value].color
            }
          }, [_vm._v(" " + _vm._s(status.label) + " ")])], 1)]) : _vm._e();
        }), 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày tạo",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchRequestGift
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;