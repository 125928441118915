"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.dialogStatus == "update" ? "Chi tiết đơn hàng" : "Thêm đơn hàng ảo",
      visible: _vm.dialogVisible,
      width: "700px",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Chọn loại"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.OrderType.Single
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Mua bài tập")]), _c("el-radio", {
    attrs: {
      label: _vm.OrderType.Pack
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Mua gói tập")])], 1), _vm.form.type == _vm.OrderType.Single ? _c("el-form-item", {
    attrs: {
      label: "Bài tập",
      prop: "title"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookId", $$v);
      },
      expression: "form.bookId"
    }
  }, _vm._l(_vm.books, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm.form.type == _vm.OrderType.Pack ? _c("el-form-item", {
    attrs: {
      label: "Gói",
      prop: "packDetailId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "filter-method": _vm.searchPackDetail,
      remote: "",
      "reserve-keyword": "",
      placeholder: "Tìm kiếm gói",
      clearable: ""
    },
    on: {
      clear: _vm.handleClearSelectPackDetail
    },
    model: {
      value: _vm.form.packDetailId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "packDetailId", $$v);
      },
      expression: "form.packDetailId"
    }
  }, _vm._l(_vm.packDetails, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Người mua",
      prop: "staffId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      "filter-method": _vm.searchStaffs,
      remote: "",
      "reserve-keyword": "",
      placeholder: "Tìm kiếm người dùng",
      clearable: ""
    },
    on: {
      clear: _vm.handleClearSelectStaff
    },
    model: {
      value: _vm.form.staffId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "staffId", $$v);
      },
      expression: "form.staffId"
    }
  }, _vm._l(_vm.staffs, function (item) {
    return _c("el-option", {
      key: item.id,
      staticClass: "truncate-option",
      attrs: {
        label: _vm.truncate("".concat(item.name, " (").concat(item.username, ")")),
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Phương thức thanh toán",
      prop: "paymentType"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: "Tất cả"
    },
    model: {
      value: _vm.form.paymentType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "paymentType", $$v);
      },
      expression: "form.paymentType"
    }
  }, _vm._l(_vm.PaymentTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      loading: _vm.submitLoading,
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogStatus == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;