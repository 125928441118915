"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HighlightBookStrategyStatusTrans = exports.HighlightBookStrategyStatusTagColor = exports.HighlightBookStrategyStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var HighlightBookStrategyStatus;
(function (HighlightBookStrategyStatus) {
  HighlightBookStrategyStatus["Pending"] = "PENDING";
  HighlightBookStrategyStatus["Approve"] = "APPROVE";
  HighlightBookStrategyStatus["Reject"] = "REJECT";
})(HighlightBookStrategyStatus || (exports.HighlightBookStrategyStatus = HighlightBookStrategyStatus = {}));
var HighlightBookStrategyStatusTrans = exports.HighlightBookStrategyStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, HighlightBookStrategyStatus.Pending, "Chờ duyệt"), HighlightBookStrategyStatus.Approve, "Đã duyệt"), HighlightBookStrategyStatus.Reject, "Từ chối");
var HighlightBookStrategyStatusTagColor = exports.HighlightBookStrategyStatusTagColor = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, HighlightBookStrategyStatus.Pending, ""), HighlightBookStrategyStatus.Approve, "success"), HighlightBookStrategyStatus.Reject, "danger");