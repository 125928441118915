"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Chi tiết câu hỏi và câu trả lời khảo sát" : "Chi tiết câu hỏi và câu trả lời khảo sát",
      visible: _vm.visible,
      width: "900px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.dataTable,
      border: "",
      fit: "",
      height: "500"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Câu hỏi"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("span", [_vm._v(_vm._s(row.question))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Câu trả lời"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("div", [_vm._l(row.quizChoices, function (item, index) {
          return [item.choice === "Tải ảnh lên" ? _vm._l(item.imageChoice, function (img) {
            return _c("div", {
              key: img,
              staticStyle: {
                display: "inline-block",
                "margin-left": "4px"
              }
            }, [_c("el-image", {
              staticStyle: {
                height: "100px"
              },
              attrs: {
                src: _vm.$url.image(img),
                alt: "",
                "preview-src-list": [_vm.$url.image(img)]
              }
            })], 1);
          }) : row.type === "multiple-choice" ? [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(item.choice ? "Nội dung phản hồi:" : "Hình ảnh:") + " " + _vm._s(item.choice)), _c("br"), _vm._l(item.imageChoice, function (img) {
            return item.imageChoice ? _c("div", {
              key: img,
              staticStyle: {
                display: "inline-block",
                "margin-left": "4px"
              }
            }, [_c("el-image", {
              staticStyle: {
                height: "100px"
              },
              attrs: {
                src: _vm.$url.image(img),
                alt: "",
                "preview-src-list": [_vm.$url.image(img)]
              }
            })], 1) : _vm._e();
          })] : [row.type == "select-time" ? _c("span", [_vm._v(" Thời điểm " + _vm._s(item.timeChoice) + " - khoảng thời gian " + _vm._s(item.choice) + " phút ")]) : _c("span", [_vm._v(_vm._s(item.choice))])]];
        })], 2)];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;