"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.to-fixed.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "chart-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item",
    staticStyle: {
      width: "200px"
    }
  }, [_c("label", {
    staticStyle: {
      display: "inline-block",
      "margin-bottom": "4px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Chọn số lượng")]), _c("el-select", {
    attrs: {
      size: "medium",
      placeholder: "Chọn số lượng"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.selectedLimit,
      callback: function callback($$v) {
        _vm.selectedLimit = $$v;
      },
      expression: "selectedLimit"
    }
  }, _vm._l(_vm.limitOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tháng")]), _c("el-date-picker", {
    attrs: {
      type: "month",
      size: "medium",
      placeholder: "Chọn tháng",
      clearable: false,
      format: "MM-yyyy"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.month,
      callback: function callback($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Thể loại")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.bookCategoryId,
      callback: function callback($$v) {
        _vm.bookCategoryId = $$v;
      },
      expression: "bookCategoryId"
    }
  }, _vm._l(_vm.bookCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " ").concat(item.syncId ? "- ".concat(item.syncId) : ""),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tác giả")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.authorId,
      callback: function callback($$v) {
        _vm.authorId = $$v;
      },
      expression: "authorId"
    }
  }, _vm._l(_vm.authors, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name),
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Gói")]), _c("el-select", {
    attrs: {
      size: "medium",
      clearable: ""
    },
    on: {
      change: _vm.fetchData
    },
    model: {
      value: _vm.productPackId,
      callback: function callback($$v) {
        _vm.productPackId = $$v;
      },
      expression: "productPackId"
    }
  }, _vm._l(_vm.productPacks, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.handleExport
    }
  }, [_vm._v("Xuất file (Excel)")])], 1), _c("div", {
    staticClass: "filter-item"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-bottom"
    },
    on: {
      click: _vm.handleExportDetail
    }
  }, [_vm._v("Xuất chi tiết (Excel)")])], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "show-summary": "",
      "summary-method": _vm.getSummaries
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Gói",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _row$productPack;
        var row = _ref.row;
        return [_c("span", [_vm._v(_vm._s((_row$productPack = row.productPack) === null || _row$productPack === void 0 ? void 0 : _row$productPack.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thể loại",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tác giả",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _row$authors;
        var row = _ref2.row;
        return [_c("span", [_vm._v(_vm._s((_row$authors = row.authors) === null || _row$authors === void 0 ? void 0 : _row$authors.map(function (e) {
          return e.name;
        }).join(", ")))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lượt xem",
      align: "right",
      prop: "viewCount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm.$formatNumberVN(row.viewCount)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số phút xem",
      align: "right",
      prop: "totalViewDuration"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.secondsToHms(row.totalViewDuration)) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Đánh giá",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("img", {
          attrs: {
            src: _vm.starIcon,
            width: "20"
          }
        }), _c("span", {
          staticStyle: {
            "font-weight": "bold",
            "vertical-align": "middle"
          }
        }, [_vm._v(_vm._s(row.totalRate == 0 ? "Mới" : (row.totalStar / row.totalRate).toFixed(1)))]), row.totalRate ? _c("div", [_c("span", {
          on: {
            click: function click($event) {
              return _vm.handleViewRate(row);
            }
          }
        }, [_vm._v(_vm._s(row.totalRate) + " đánh giá")]), _c("div", [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-view"
          },
          on: {
            click: function click($event) {
              return _vm.handleViewRate(row);
            }
          }
        }, [_vm._v("Xem")])], 1)]) : _vm._e()];
      }
    }])
  })], 1), _c("label", {
    staticClass: "chart-title",
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v(" Top " + _vm._s(_vm.selectedLimit) + " nội dung số được xem nhiều nhất ")]), _c("RatingModal", {
    ref: "RatingModal",
    on: {
      submitOk: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;