"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecommendBookStrategyStatusTrans = exports.RecommendBookStrategyStatus = exports.HighlightBookStrategyStatusTagColor = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var RecommendBookStrategyStatus;
(function (RecommendBookStrategyStatus) {
  RecommendBookStrategyStatus["Pending"] = "PENDING";
  RecommendBookStrategyStatus["Approve"] = "APPROVE";
  RecommendBookStrategyStatus["Reject"] = "REJECT";
})(RecommendBookStrategyStatus || (exports.RecommendBookStrategyStatus = RecommendBookStrategyStatus = {}));
var RecommendBookStrategyStatusTrans = exports.RecommendBookStrategyStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, RecommendBookStrategyStatus.Pending, "Chờ duyệt"), RecommendBookStrategyStatus.Approve, "Đã duyệt"), RecommendBookStrategyStatus.Reject, "Từ chối");
var HighlightBookStrategyStatusTagColor = exports.HighlightBookStrategyStatusTagColor = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, RecommendBookStrategyStatus.Pending, ""), RecommendBookStrategyStatus.Approve, "success"), RecommendBookStrategyStatus.Reject, "danger");