"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefHistoryTypeTrans = exports.RefHistoryType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var RefHistoryType;
(function (RefHistoryType) {
  RefHistoryType["Parent2Customer"] = "PARENT_2_CUSTOMER";
  RefHistoryType["PArent2Parent"] = "PARENT_2_PARENT";
})(RefHistoryType || (exports.RefHistoryType = RefHistoryType = {}));
var RefHistoryTypeTrans = exports.RefHistoryTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, RefHistoryType.Parent2Customer, "Giới thiệu cho người dùng"), RefHistoryType.PArent2Parent, "Giới thiệu cho cấp con");