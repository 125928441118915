"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? "Thêm mới loại người dùng" : "Cập nhật loại người dùng",
      visible: _vm.visible,
      width: "600px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã loại người dùng",
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.status == "update",
      size: "medium"
    },
    model: {
      value: _vm.form.username,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên loại người dùng",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      "show-word-limit": "",
      maxlength: 40,
      size: "medium"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tuổi từ",
      prop: "minAge"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        max: 99
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              max: 99\n            }"
    }],
    model: {
      value: _vm.form.minAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minAge", $$v);
      },
      expression: "form.minAge"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tuổi đến",
      prop: "maxAge"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false,
        max: 99
      },
      expression: "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              max: 99,\n            }"
    }],
    model: {
      value: _vm.form.maxAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "maxAge", $$v);
      },
      expression: "form.maxAge"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giới tính",
      prop: "gender"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }, _vm._l(_vm.GenderTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại người dùng",
      prop: "roleId"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium",
      disabled: ""
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }, _vm._l(_vm.roles, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.status != "create" ? _c("div", [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "danger",
      size: "default",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.handleDelete
    }
  }, [_vm._v("Xóa")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium",
      type: "warning",
      loading: _vm.loadingReset
    },
    on: {
      click: _vm.handleResetPwd
    }
  }, [_vm._v("Reset mật khẩu")])], 1) : _vm._e(), _c("div", {}, [_c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      size: "medium"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    staticClass: "font-bold font-size--medium",
    attrs: {
      type: "primary",
      size: "medium",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;