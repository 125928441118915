"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.giftTypeTrans = exports.GiftType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var GiftType;
(function (GiftType) {
  GiftType["Physic"] = "PHYSIC";
  GiftType["Book"] = "BOOK";
})(GiftType || (exports.GiftType = GiftType = {}));
var giftTypeTrans = exports.giftTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, GiftType.Physic, {
  label: "Quà vật lý",
  value: GiftType.Physic
}), GiftType.Book, {
  label: "Bài tập",
  value: GiftType.Book
});