"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommissionConfigTypeTrans = exports.CommissionConfigType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CommissionConfigType;
(function (CommissionConfigType) {
  CommissionConfigType["First"] = "FIRST";
  CommissionConfigType["FirstTier2"] = "FIRST_TIER2";
  CommissionConfigType["FirstTier3"] = "FIRST_TIER3";
  CommissionConfigType["Tier1"] = "TIER1";
  CommissionConfigType["Tier2"] = "TIER2";
  CommissionConfigType["Tier3"] = "TIER3";
  CommissionConfigType["TeamSales"] = "TEAM_SALES";
})(CommissionConfigType || (exports.CommissionConfigType = CommissionConfigType = {}));
var CommissionConfigTypeTrans = exports.CommissionConfigTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CommissionConfigType.First, "Chiết khấu lần đầu cho doanh số 1"), CommissionConfigType.FirstTier2, "Chiết khấu lần đầu cho doanh số 2"), CommissionConfigType.FirstTier3, "Chiết khấu lần đầu cho doanh số 3"), CommissionConfigType.Tier1, "Doanh số dưới [Mức doanh số 1]: Chiết khấu [Tỷ lệ chiết khấu 1 ] %"), CommissionConfigType.Tier2, "Doanh số từ [Mức doanh số 1] đến [Mức doanh số 2]: Chiết khấu [Tỷ lệ chiết khấu 2] %"), CommissionConfigType.Tier3, "Doanh số từ [Mức doanh số 2] trở lên: Chiết khấu [Tỷ lệ chiết khấu 3] %"), CommissionConfigType.TeamSales, "Doanh số phát triển đội nhóm, đạt đủ điều kiện thì được thưởng thêm chiết khấu");