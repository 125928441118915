"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Cập nhật cấu hình",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cấu hình",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.configReminderTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("div", [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Việt",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Anh",
      prop: "titleEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.titleEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "titleEn", $$v);
      },
      expression: "form.titleEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề tiếng Nhật",
      prop: "titleJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.titleJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "titleJp", $$v);
      },
      expression: "form.titleJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Việt",
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Anh",
      prop: "contentEn"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.contentEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contentEn", $$v);
      },
      expression: "form.contentEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Nhật",
      prop: "contentJp"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.contentJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contentJp", $$v);
      },
      expression: "form.contentJp"
    }
  })], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "z-index": "99"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;