"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expertCommentApi = void 0;
var _request = require("@/utils/request");
var expertCommentApi = exports.expertCommentApi = {
  create: function create(data) {
    return (0, _request.request)({
      url: "/v1/admin/expertComment",
      data: data,
      method: "post"
    });
  }
};