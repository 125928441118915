"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.commissionConfig.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "type", $$v);
      },
      expression: "form.commissionConfig.type"
    }
  }, _vm._l(_vm.CommissionConfigTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "% hoa hồng"
    }
  }, [_c("el-input-number", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    attrs: {
      max: 100
    },
    model: {
      value: _vm.form.commissionConfig.percent,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "percent", $$v);
      },
      expression: "form.commissionConfig.percent"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Doanh số tối thiểu"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    model: {
      value: _vm.form.commissionConfig.minAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "minAmount", $$v);
      },
      expression: "form.commissionConfig.minAmount"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Doanh số tối đa"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    model: {
      value: _vm.form.commissionConfig.maxAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "maxAmount", $$v);
      },
      expression: "form.commissionConfig.maxAmount"
    }
  })], 1), _vm.form.commissionConfig.type == _vm.CommissionConfigType.TeamSales ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Số lượng giới thiệu tối thiểu"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
    }],
    model: {
      value: _vm.form.commissionConfig.ref,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "ref", $$v);
      },
      expression: "form.commissionConfig.ref"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số lượng nhà phân phối"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
    }],
    model: {
      value: _vm.form.commissionConfig.refNPPP,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "refNPPP", $$v);
      },
      expression: "form.commissionConfig.refNPPP"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Doanh số của NPPP/tháng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n            alias: 'decimal',\n            groupSeparator: '.',\n            rightAlign: false,\n            autoGroup: true,\n            repeat: 20,\n            autoUnmask: true,\n            allowMinus: false,\n          }"
    }],
    model: {
      value: _vm.form.commissionConfig.totalNPPPMinAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.form.commissionConfig, "totalNPPPMinAmount", $$v);
      },
      expression: "form.commissionConfig.totalNPPPMinAmount"
    }
  })], 1)], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;