"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/298yoga-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.conversationApi = void 0;
exports.findAll = findAll;
exports.findOne = findOne;
var _request = _interopRequireDefault(require("@/utils/request"));
var conversationApi = exports.conversationApi = {
  findAll: findAll,
  findOne: findOne
};
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/v1/admin/chatbot",
    method: "get",
    params: listQuery
  });
}
function findOne(_ref) {
  var userId = _ref.userId,
    listQuery = _ref.listQuery;
  return (0, _request.default)({
    url: "/v1/admin/chatbot/web/".concat(userId),
    method: "get",
    params: listQuery
  });
}