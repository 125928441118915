"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật sách" : "Thêm mới sách",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Tiếng Việt",
      name: "vi"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên sách (Tiếng Việt)",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      gap: "10px"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá trước khuyến mãi (VNĐ)",
      prop: "discountPrice"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.discountPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discountPrice", $$v);
      },
      expression: "form.discountPrice"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá sau khuyến mãi (VNĐ)",
      prop: "price"
    }
  }, [_c("el-input", {
    attrs: {
      "decimal-separator": "."
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Keyword (Tiếng Việt)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keyword", $$v);
      },
      expression: "form.keyword"
    }
  })], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Tiếng Anh",
      name: "en"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên sách (Tiếng Anh)",
      prop: "nameEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameEn", $$v);
      },
      expression: "form.nameEn"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      gap: "10px"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá trước khuyến mãi (USD)",
      prop: "discountPriceEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.discountPriceEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discountPriceEn", $$v);
      },
      expression: "form.discountPriceEn"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá sau khuyến mãi (USD)",
      prop: "priceEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.priceEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceEn", $$v);
      },
      expression: "form.priceEn"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.descriptionEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "descriptionEn", $$v);
      },
      expression: "form.descriptionEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Keyword (Tiếng Anh)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.keywordEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keywordEn", $$v);
      },
      expression: "form.keywordEn"
    }
  })], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Tiếng Nhật",
      name: "jp"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên sách (Tiếng Nhật)",
      prop: "nameJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.nameJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nameJp", $$v);
      },
      expression: "form.nameJp"
    }
  })], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      gap: "10px"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá trước khuyến mãi (JPY)",
      prop: "discountPriceJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.discountPriceJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "discountPriceJp", $$v);
      },
      expression: "form.discountPriceJp"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "Giá sau khuyến mãi (JPY)",
      prop: "priceJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.priceJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priceJp", $$v);
      },
      expression: "form.priceJp"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.descriptionJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "descriptionJp", $$v);
      },
      expression: "form.descriptionJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Keyword (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 3,
        maxRows: 7
      }
    },
    model: {
      value: _vm.form.keywordJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "keywordJp", $$v);
      },
      expression: "form.keywordJp"
    }
  })], 1)], 1)], 1), _vm.status == "update" ? _c("el-form-item", {
    attrs: {
      label: "Mã nội bộ",
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Số ngày hết hạn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.expiresDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiresDay", $$v);
      },
      expression: "form.expiresDay"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số ngày hạn mua trên web"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.webShopExpireDay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "webShopExpireDay", $$v);
      },
      expression: "form.webShopExpireDay"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "ISBN code"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.isbnCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isbnCode", $$v);
      },
      expression: "form.isbnCode"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Apple Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.appleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "appleId", $$v);
      },
      expression: "form.appleId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Google Id"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.googleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "googleId", $$v);
      },
      expression: "form.googleId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mã sản phẩm(shop)"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "syncId"
    },
    model: {
      value: _vm.form.syncId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "syncId", $$v);
      },
      expression: "form.syncId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Đường dẫn đến sách trên shopvisedu"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "url"
    },
    model: {
      value: _vm.form.urlBookShop,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "urlBookShop", $$v);
      },
      expression: "form.urlBookShop"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Link video preview của sách"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "url"
    },
    model: {
      value: _vm.form.urlPreviewVideoBook,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "urlPreviewVideoBook", $$v);
      },
      expression: "form.urlPreviewVideoBook"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tổng số trang"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    model: {
      value: _vm.form.totalPage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "totalPage", $$v);
      },
      expression: "form.totalPage"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Định dạng sách",
      prop: "type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.BookType.Physical
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Sách giấy")]), _c("el-radio", {
    attrs: {
      label: _vm.BookType.EBook
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Sách điện tử")]), _c("el-radio", {
    attrs: {
      label: _vm.BookType.SlimmingPillow
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Gói sản phẩm")]), _c("el-radio", {
    attrs: {
      label: _vm.BookType.Product
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_vm._v("Sản phẩm vật lý")])], 1), _vm.form.type == _vm.BookType.SlimmingPillow ? _c("el-form-item", {
    attrs: {
      label: "Sản phẩm đi kèm",
      prop: "bookIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.bookIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookIds", $$v);
      },
      expression: "form.bookIds"
    }
  }, _vm._l(_vm.booksTypeProduct, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Cho phép phương thức thanh toán"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentApple,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentApple", $$v);
      },
      expression: "form.enablePaymentApple"
    }
  }, [_vm._v("Apple")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentGoogle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentGoogle", $$v);
      },
      expression: "form.enablePaymentGoogle"
    }
  }, [_vm._v("Google")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentAlepay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentAlepay", $$v);
      },
      expression: "form.enablePaymentAlepay"
    }
  }, [_vm._v("AlePay")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentPaypal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentPaypal", $$v);
      },
      expression: "form.enablePaymentPaypal"
    }
  }, [_vm._v("Paypal")])], 1), _c("el-form-item", {
    attrs: {
      label: "Phát hành",
      prop: "isPublished"
    }
  }, [_c("el-radio", {
    attrs: {
      label: true
    },
    model: {
      value: _vm.form.isPublished,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isPublished", $$v);
      },
      expression: "form.isPublished"
    }
  }, [_vm._v("Đã phát hành")]), _c("el-radio", {
    attrs: {
      label: false
    },
    model: {
      value: _vm.form.isPublished,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isPublished", $$v);
      },
      expression: "form.isPublished"
    }
  }, [_vm._v("Chưa phát hành")])], 1), _c("el-form-item", {
    attrs: {
      label: "Tác giả",
      prop: "authorId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: ""
    },
    model: {
      value: _vm.form.authorIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "authorIds", $$v);
      },
      expression: "form.authorIds"
    }
  }, _vm._l(_vm.authors, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1), _c("el-button", {
    staticClass: "add-btn-float font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddAuthor
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nhà xuất bản"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "",
      clearable: ""
    },
    model: {
      value: _vm.form.publisher,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "publisher", $$v);
      },
      expression: "form.publisher"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Năm xuất bản"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "year",
      format: "yyyy",
      "value-format": "yyyy"
    },
    model: {
      value: _vm.form.publishDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "publishDate", $$v);
      },
      expression: "form.publishDate"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Kho"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.warehouseIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "warehouseIds", $$v);
      },
      expression: "form.warehouseIds"
    }
  }, _vm._l(_vm.warehouses, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Thể loại sách",
      prop: "bookCategoryId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.form.bookCategoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookCategoryId", $$v);
      },
      expression: "form.bookCategoryId"
    }
  }, _vm._l(_vm.bookCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "".concat(item.name, " ").concat(item.syncId ? "- ".concat(item.syncId) : ""),
        value: item.id
      }
    });
  }), 1), _c("el-button", {
    staticClass: "add-btn-float font-bold",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddCategory
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Ảnh bìa",
      prop: "thumbnail"
    }
  }, [_c("SingleImage", {
    attrs: {
      pathImage: _vm.form.thumbnail,
      urlUpload: "/v1/admin/book/upload"
    },
    on: {
      "upload:success": _vm.setThumbnail
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1), _c("AuthorModal", {
    ref: "AuthorModal",
    on: {
      submitOk: _vm.fetchAuthors
    }
  }), _c("BookCategoryModal", {
    ref: "BookCategoryModal",
    on: {
      submitOk: _vm.fetchBookCategories
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;