"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.link.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.status == "update" ? "Cập nhật popup" : "Thêm popup",
      visible: _vm.visible,
      width: "80vw",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Thông tin",
      name: "info"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-width": "80px",
      "label-position": "top"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "column-gap": "24px",
      "flex-wrap": "wrap"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại",
      prop: "type"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "220px"
    },
    attrs: {
      placeholder: "Chọn loại"
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.PopupTypeTrans, function (value, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: value,
        value: key
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Thời gian"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "460px"
    },
    attrs: {
      type: "daterange",
      "range-separator": "đến",
      "start-placeholder": "Bắt đầu",
      "end-placeholder": "Kết thúc",
      "value-format": "timestamp",
      format: "dd/MM/yyyy",
      "picker-options": {
        disabledDate: _vm.handleDisableDate
      }
    },
    model: {
      value: _vm.form.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dateRange", $$v);
      },
      expression: "form.dateRange"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Thời gian hiện nút bấm (giây)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.form.closeInSecond,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "closeInSecond", $$v);
      },
      expression: "form.closeInSecond"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Chiều cao popup (%)"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number"
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hiển thị",
      prop: "isVisible"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ccc"
    },
    model: {
      value: _vm.form.isVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isVisible", $$v);
      },
      expression: "form.isVisible"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hiển thị nút like",
      prop: "isAllowLike"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ccc"
    },
    model: {
      value: _vm.form.isAllowLike,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isAllowLike", $$v);
      },
      expression: "form.isAllowLike"
    }
  })], 1)], 1), _vm.form.type === _vm.PopupType.Book ? _c("el-form-item", {
    attrs: {
      label: "Chọn sách",
      prop: "bookId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "460px"
    },
    attrs: {
      placeholder: "Chọn sách"
    },
    model: {
      value: _vm.form.bookId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bookId", $$v);
      },
      expression: "form.bookId"
    }
  }, _vm._l(_vm.books, function (book) {
    return _c("el-option", {
      key: book === null || book === void 0 ? void 0 : book.id,
      attrs: {
        label: _vm.getBookLabel(book),
        value: book === null || book === void 0 ? void 0 : book.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-tabs", {
    model: {
      value: _vm.activeSubTab,
      callback: function callback($$v) {
        _vm.activeSubTab = $$v;
      },
      expression: "activeSubTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Tiếng Việt",
      name: "vi"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (khuyến nghị tỉ lệ 20:8, dung lượng tối đa 1MB)",
      prop: "imageVi"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.thumbnail,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUploadSuccess(path, "vi");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên popup",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả ngắn",
      prop: "shortDescription"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.shortDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shortDescription", $$v);
      },
      expression: "form.shortDescription"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung nút bấm",
      prop: "buttonText"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.buttonText,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "buttonText", $$v);
      },
      expression: "form.buttonText"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung like",
      prop: "likeContent"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.likeContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "likeContent", $$v);
      },
      expression: "form.likeContent"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung dislike",
      prop: "dislikeContent"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.dislikeContent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dislikeContent", $$v);
      },
      expression: "form.dislikeContent"
    }
  })], 1), _vm.form.type === _vm.PopupType.Link ? _c("el-form-item", {
    attrs: {
      label: "Liên kết",
      prop: "linkVi"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Nhập liên kết"
    },
    model: {
      value: _vm.form.link,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "link", $$v);
      },
      expression: "form.link"
    }
  })], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung",
      prop: "description"
    }
  }, [_c("Tinymce", {
    ref: "editorVi",
    attrs: {
      width: "100%",
      height: 400
    },
    on: {
      onInit: function onInit($event) {
        return _vm.handleInit("vi");
      }
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Tiếng Anh",
      name: "en"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (khuyến nghị tỉ lệ 20:8, dung lượng tối đa 1MB)",
      prop: "imageEn"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.thumbnailEn,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUploadSuccess(path, "en");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên popup",
      prop: "titleEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.titleEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "titleEn", $$v);
      },
      expression: "form.titleEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả ngắn",
      prop: "shortDescriptionEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.shortDescriptionEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shortDescriptionEn", $$v);
      },
      expression: "form.shortDescriptionEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung nút bấm",
      prop: "buttonTextEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.buttonTextEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "buttonTextEn", $$v);
      },
      expression: "form.buttonTextEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung like",
      prop: "buttonTextEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.likeContentEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "likeContentEn", $$v);
      },
      expression: "form.likeContentEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung dislike",
      prop: "buttonTextEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.dislikeContentEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dislikeContentEn", $$v);
      },
      expression: "form.dislikeContentEn"
    }
  })], 1), _vm.form.type === _vm.PopupType.Link ? _c("el-form-item", {
    attrs: {
      label: "Liên kết",
      prop: "linkEn"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Nhập liên kết"
    },
    model: {
      value: _vm.form.linkEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "linkEn", $$v);
      },
      expression: "form.linkEn"
    }
  })], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung",
      prop: "descriptionEn"
    }
  }, [_c("Tinymce", {
    ref: "editorEn",
    attrs: {
      width: "100%",
      height: 400
    },
    on: {
      onInit: function onInit($event) {
        return _vm.handleInit("en");
      }
    },
    model: {
      value: _vm.form.descriptionEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "descriptionEn", $$v);
      },
      expression: "form.descriptionEn"
    }
  })], 1)], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "Tiếng Nhật",
      name: "jp"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (khuyến nghị tỉ lệ 20:8, dung lượng tối đa 1MB)",
      prop: "imageJp"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.thumbnailJp,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.handleUploadSuccess(path, "jp");
      }
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tên popup",
      prop: "titleJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.titleJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "titleJp", $$v);
      },
      expression: "form.titleJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mô tả ngắn",
      prop: "shortDescriptionJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.shortDescriptionJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "shortDescriptionJp", $$v);
      },
      expression: "form.shortDescriptionJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung nút bấm",
      prop: "buttonTextJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.buttonTextJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "buttonTextJp", $$v);
      },
      expression: "form.buttonTextJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung like",
      prop: "likeContentJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.likeContentJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "likeContentJp", $$v);
      },
      expression: "form.likeContentJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Nội dung dislike",
      prop: "dislikeContentJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.dislikeContentJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dislikeContentJp", $$v);
      },
      expression: "form.dislikeContentJp"
    }
  })], 1), _vm.form.type === _vm.PopupType.Link ? _c("el-form-item", {
    attrs: {
      label: "Liên kết",
      prop: "linkJp"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Nhập liên kết"
    },
    model: {
      value: _vm.form.linkJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "linkJp", $$v);
      },
      expression: "form.linkJp"
    }
  })], 1) : _vm._e()], 1), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Nội dung",
      prop: "descriptionJp"
    }
  }, [_c("Tinymce", {
    ref: "editorJp",
    attrs: {
      width: "100%",
      height: 400
    },
    on: {
      onInit: function onInit($event) {
        return _vm.handleInit("jp");
      }
    },
    model: {
      value: _vm.form.descriptionJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "descriptionJp", $$v);
      },
      expression: "form.descriptionJp"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _vm.status == "update" ? _c("el-tab-pane", {
    attrs: {
      label: "Khách hàng tương tác",
      name: "favorite"
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Trạng thái yêu thích")]), _c("el-select", {
    attrs: {
      clearable: "",
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.fetchFavorite
    },
    model: {
      value: _vm.queryStaff.isLiked,
      callback: function callback($$v) {
        _vm.$set(_vm.queryStaff, "isLiked", $$v);
      },
      expression: "queryStaff.isLiked"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Yêu thích",
      value: true
    }
  }), _c("el-option", {
    attrs: {
      label: "Không thích",
      value: false
    }
  })], 1)], 1)]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.favorites,
      fit: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.staff.avatar ? _vm.$url.image(row.staff.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.staff.name))])]), _c("span", [_vm._v("Nguồn: " + _vm._s(row.staff.createFrom == _vm.CreateFrom.Self ? "Portal" : "Sync"))]), row.staff.azureId ? _c("div", [_vm._v("Azure Id: " + _vm._s(row.staff.azureId))]) : _vm._e(), row.staff.azureEmail ? _c("div", [_vm._v(" Azure email: " + _vm._s(row.staff.azureEmail) + " ")]) : _vm._e()];
      }
    }], null, false, 2392470506)
  }), _c("el-table-column", {
    attrs: {
      label: "Username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row.staff.username))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SyncId: ")]), _c("span", [_vm._v(_vm._s(row.staff.syncId))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mật khẩu khởi tạo: ")]), _c("span", [_vm._v(_vm._s(row.staff.initPassword))])];
      }
    }], null, false, 1104915093)
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại",
      prop: "staff.phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin thêm",
      prop: "other"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), row.staff.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.staff.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), row.staff.dob ? _c("span", [_vm._v(_vm._s(row.staff.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), row.staff.email ? _c("span", [_vm._v(_vm._s(row.staff.email))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), row.staff.zalo ? _c("span", [_vm._v(_vm._s(row.staff.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), row.staff.facebook ? _c("span", [_vm._v(_vm._s(row.staff.facebook))]) : _c("span", [_vm._v("--")])];
      }
    }], null, false, 3465052980)
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái kích hoạt",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [!row.staff.isBlocked ? [_c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.staff.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.staff.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.expiredAt)))]) : _vm._e()] : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Chưa kích hoạt ")])];
      }
    }], null, false, 4293390682)
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái yêu thích",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [row.isLiked ? _c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Yêu thích ")]) : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Không thích ")])];
      }
    }], null, false, 3789250539)
  }), _c("el-table-column", {
    attrs: {
      label: "Tương tác lúc",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_vm._v(" " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " ")];
      }
    }], null, false, 3391742349)
  })], 1)], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;