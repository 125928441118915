"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "book-item"
  }, [_c("div", {
    staticClass: "book-image"
  }, [_c("img", {
    attrs: {
      src: _vm.$url.image(_vm.book.thumbnail),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "book-content"
  }, [_c("section", {
    staticStyle: {
      "max-height": "100%",
      overflow: "hidden"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("span", {
    staticClass: "book-name"
  }, [_vm._v(_vm._s(_vm.book.name))]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Tác giả: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.authors.map(function (e) {
    return e.name;
  }).join(", ")) + " ")])]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Thể loại: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.bookCategory ? _vm.book.bookCategory.name : "--") + " ")])]), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Năm xuất bản: ")]), _c("span", {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.book.publishDate ? _vm.book.publishDate : "--") + " ")])])]), _c("QrcodeVue", {
    staticClass: "qr-code",
    attrs: {
      value: "book--".concat(_vm.book.code),
      size: _vm.qrSize
    }
  })], 1), _c("div", {
    staticClass: "book-field"
  }, [_c("span", {
    staticClass: "value description"
  }, [_vm._v(" Mô tả: " + _vm._s(_vm.book.description ? _vm.book.description : "--") + " ")])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;