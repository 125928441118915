"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
      visible: _vm.visible,
      width: "700px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Hình ảnh (375x812)",
      prop: "image"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.form.surveyCampaign.image,
      urlUpload: "/v1/admin/news/upload"
    },
    on: {
      "upload:success": _vm.handleUploadSuccess
    }
  })], 1), _vm.form.surveyCampaign.type == _vm.SurveyCampaignType.OpenApp ? _c("div", {
    staticStyle: {
      display: "flex",
      gap: "50px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thời gian"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "daterange",
      "range-separator": "đến",
      "start-placeholder": "Bắt đầu",
      "end-placeholder": "Kết thúc",
      "value-format": "timestamp",
      format: "dd/MM/yyyy",
      "picker-options": {
        disabledDate: _vm.handleDisableDate
      }
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Hiển thị",
      prop: "isVisible"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ccc"
    },
    model: {
      value: _vm.form.surveyCampaign.isVisible,
      callback: function callback($$v) {
        _vm.$set(_vm.form.surveyCampaign, "isVisible", $$v);
      },
      expression: "form.surveyCampaign.isVisible"
    }
  })], 1)], 1) : _vm._e(), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề khảo sát (Tiếng Việt)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.surveyCampaign.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form.surveyCampaign, "title", $$v);
      },
      expression: "form.surveyCampaign.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề khảo sát (Tiếng Anh)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.surveyCampaign.titleEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form.surveyCampaign, "titleEn", $$v);
      },
      expression: "form.surveyCampaign.titleEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Tiêu đề khảo sát (Tiếng Nhật)"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.surveyCampaign.titleJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form.surveyCampaign, "titleJp", $$v);
      },
      expression: "form.surveyCampaign.titleJp"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Các lựa chọn"
    }
  }, [_vm._l(_vm.form.details, function (detail, index) {
    return _c("div", {
      key: index,
      staticStyle: {
        "margin-top": "10px",
        border: "1px solid #ccc",
        padding: "10px",
        "border-radius": "4px"
      }
    }, [_c("el-form-item", {
      attrs: {
        label: "Lựa chọn tiếng Việt"
      }
    }, [_c("el-input", {
      model: {
        value: detail.content,
        callback: function callback($$v) {
          _vm.$set(detail, "content", $$v);
        },
        expression: "detail.content"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        label: "Lựa chọn tiếng Anh"
      }
    }, [_c("el-input", {
      model: {
        value: detail.contentEn,
        callback: function callback($$v) {
          _vm.$set(detail, "contentEn", $$v);
        },
        expression: "detail.contentEn"
      }
    })], 1), _c("el-form-item", {
      attrs: {
        label: "Lựa chọn tiếng Nhật"
      }
    }, [_c("el-input", {
      model: {
        value: detail.contentJp,
        callback: function callback($$v) {
          _vm.$set(detail, "contentJp", $$v);
        },
        expression: "detail.contentJp"
      }
    })], 1), _c("el-button", {
      attrs: {
        type: "danger",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeDetail(index);
        }
      }
    }, [_vm._v("Xóa lựa chọn")])], 1);
  }), _c("el-button", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.addDetail
    }
  }, [_vm._v("Thêm lựa chọn")])], 2)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;