"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.search.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tìm kiếm")]), _c("el-input", {
    attrs: {
      placeholder: "Tìm tên, sđt, email",
      size: "medium"
    },
    nativeOn: {
      keydown: function keydown($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleQuery.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.search,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "search", $$v);
      },
      expression: "query.search"
    }
  })], 1)])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.agencyChildren,
      fit: "",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      type: "selection"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tên",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", [_c("img", {
          staticStyle: {
            "vertical-align": "middle",
            "border-radius": "50%",
            "object-fit": "cover",
            "margin-right": "8px"
          },
          attrs: {
            width: "40",
            height: "40",
            src: row.avatar ? _vm.$url.image(row.avatar) : _vm.avatarDefault,
            alt: ""
          }
        }), _c("label", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            for: ""
          }
        }, [_vm._v(_vm._s(row.name))])]), _c("span", [_vm._v("Nguồn: " + _vm._s(row.createFrom == _vm.CreateFrom.Self ? "Portal" : "Sync"))]), row.azureId ? _c("div", [_vm._v("Azure Id: " + _vm._s(row.azureId))]) : _vm._e(), row.azureEmail ? _c("div", [_vm._v("Azure email: " + _vm._s(row.azureEmail))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Username"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Username: ")]), _c("span", [_vm._v(_vm._s(row.username))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("SyncId: ")]), _c("span", [_vm._v(_vm._s(row.syncId))]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Mật khẩu khởi tạo: ")]), _c("span", [_vm._v(_vm._s(row.initPassword))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số điện thoại",
      prop: "phone"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Thông tin thêm",
      prop: "other"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _row$role, _row$role2;
        var row = _ref3.row;
        return [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Giới tính: ")]), row.gender ? _c("span", [_vm._v(" " + _vm._s(_vm.GenderTrans[row.gender]))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Ngày sinh: ")]), row.dob ? _c("span", [_vm._v(_vm._s(row.dob))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Email: ")]), row.email ? _c("span", [_vm._v(_vm._s(row.email))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Zalo: ")]), row.zalo ? _c("span", [_vm._v(_vm._s(row.zalo))]) : _c("span", [_vm._v("--")]), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("Facebook: ")]), row.facebook ? _c("span", [_vm._v(_vm._s(row.facebook))]) : _c("span", [_vm._v("--")]), _c("br"), ((_row$role = row.role) === null || _row$role === void 0 ? void 0 : _row$role.type) === _vm.SyncPortalRole.DaiLy || ((_row$role2 = row.role) === null || _row$role2 === void 0 ? void 0 : _row$role2.type) === _vm.SyncPortalRole.DaiLy2 ? _c("div", [_c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v("% hoa hồng: ")]), row.commissionPercent ? _c("span", [_vm._v(_vm._s(row.commissionPercent))]) : _c("span", [_vm._v("--")])]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Lớp/Nhóm",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [row.classrooms ? _c("div", [_vm._v(" " + _vm._s(row.classrooms.map(function (e) {
          return e.name;
        }).join(", ")) + " ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Loại người dùng"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(row.role ? row.role.name : "-") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Trạng thái kích hoạt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [!row.isBlocked ? [_c("el-tag", {
          attrs: {
            type: "success",
            effect: "dark"
          }
        }, [_vm._v(" Đã kích hoạt ")]), _c("br"), row.expiredAt ? _c("span", [_vm._v("Hạn dùng: " + _vm._s(_vm.$formatDate(row.startExpiredAt)) + " - " + _vm._s(_vm.$formatDate(row.expiredAt)))]) : _vm._e()] : _c("el-tag", {
          attrs: {
            type: "danger",
            effect: "dark"
          }
        }, [_vm._v(" Chưa kích hoạt ")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit,
      pageSizes: [10, 20, 30, 50, 100]
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;