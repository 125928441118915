"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Cập nhật cấu hình",
      visible: _vm.visible,
      width: "500px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Cấu hình",
      prop: "type"
    }
  }, [_c("el-select", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, _vm._l(_vm.ConfigurationTypeTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1), _vm.form.type == _vm.ConfigurationType.AITutorialLink || _vm.form.type == _vm.ConfigurationType.SurveyAppText ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Link tiếng Việt",
      prop: "value"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Link tiếng Anh",
      prop: "valueEn"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.valueEn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueEn", $$v);
      },
      expression: "form.valueEn"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Link tiếng Nhật",
      prop: "valueJp"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.valueJp,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueJp", $$v);
      },
      expression: "form.valueJp"
    }
  })], 1)], 1) : _vm.form.type === _vm.ConfigurationType.PurchaseUnblockAllContent ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Việt",
      prop: "value"
    }
  }, [_vm._l(_vm.vietnameseContents, function (item, index) {
    return _c("div", {
      key: "vn-" + index,
      staticClass: "input-group"
    }, [_c("el-input", {
      attrs: {
        placeholder: "Nội dung tiếng Việt dòng " + (index + 1)
      },
      model: {
        value: _vm.vietnameseContents[index],
        callback: function callback($$v) {
          _vm.$set(_vm.vietnameseContents, index, $$v);
        },
        expression: "vietnameseContents[index]"
      }
    }), _c("el-button", {
      attrs: {
        type: "danger",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeContent("vn", index);
        }
      }
    })], 1);
  }), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.addContent("vn");
      }
    }
  }, [_vm._v("Thêm nội dung tiếng Việt")])], 2), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Anh",
      prop: "valueEn"
    }
  }, [_vm._l(_vm.englishContents, function (item, index) {
    return _c("div", {
      key: "en-" + index,
      staticClass: "input-group"
    }, [_c("el-input", {
      attrs: {
        placeholder: "Nội dung tiếng Anh dòng " + (index + 1)
      },
      model: {
        value: _vm.englishContents[index],
        callback: function callback($$v) {
          _vm.$set(_vm.englishContents, index, $$v);
        },
        expression: "englishContents[index]"
      }
    }), _c("el-button", {
      attrs: {
        type: "danger",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeContent("en", index);
        }
      }
    })], 1);
  }), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.addContent("en");
      }
    }
  }, [_vm._v("Thêm nội dung tiếng Anh")])], 2), _c("el-form-item", {
    attrs: {
      label: "Nội dung tiếng Nhật",
      prop: "valueJp"
    }
  }, [_vm._l(_vm.japaneseContents, function (item, index) {
    return _c("div", {
      key: "jp-" + index,
      staticClass: "input-group"
    }, [_c("el-input", {
      attrs: {
        placeholder: "Nội dung tiếng Nhật dòng " + (index + 1)
      },
      model: {
        value: _vm.japaneseContents[index],
        callback: function callback($$v) {
          _vm.$set(_vm.japaneseContents, index, $$v);
        },
        expression: "japaneseContents[index]"
      }
    }), _c("el-button", {
      attrs: {
        type: "danger",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removeContent("jp", index);
        }
      }
    })], 1);
  }), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.addContent("jp");
      }
    }
  }, [_vm._v("Thêm nội dung tiếng Nhật")])], 2)], 1) : _c("el-form-item", {
    attrs: {
      label: "Giá trị",
      prop: "value"
    }
  }, [_vm.form.dataType == "number" ? _c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{\n          alias: 'decimal',\n          groupSeparator: '.',\n          rightAlign: false,\n          autoGroup: true,\n          repeat: 20,\n          autoUnmask: true,\n          allowMinus: false,\n        }"
    }],
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }) : _vm.form.dataType == "images" ? _c("el-upload", {
    staticClass: "editor-slide-upload",
    attrs: {
      multiple: true,
      "file-list": _vm.fileList,
      "show-file-list": true,
      "on-preview": _vm.handlePreview,
      "on-remove": _vm.handleRemove,
      "on-success": _vm.handleSuccess,
      action: "".concat(_vm.$uploadDomain, "/v1/admin/news/upload"),
      "list-type": "picture-card",
      name: "file",
      headers: {
        token: _vm.token
      }
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v("Click upload")])], 1) : _vm.form.dataType == "string" ? _c("el-input", {
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }) : [_c("el-radio", {
    attrs: {
      label: "true"
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, [_vm._v("Có")]), _c("el-radio", {
    attrs: {
      label: "false"
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }, [_vm._v("Không")])]], 2)], 1), _vm.form.type == "FACE_DETECTION_TYPE" ? _c("small", [_vm._v(" Giá trị "), _c("br"), _vm._v("0: Nhận diện Tuổi, Giới tính "), _c("br"), _vm._v("1: Nhận diện ID "), _c("br"), _vm._v("2: Nhận diện hỗn hợp ")]) : _vm._e(), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.updateData
    }
  }, [_vm._v("Đồng ý")])], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      "z-index": "99"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;