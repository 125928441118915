"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticClass: "recommend-book-modal",
    attrs: {
      title: _vm.status == "update" ? "Cập nhật gói tập" : "Thêm gói tập",
      visible: _vm.visible,
      width: "900px",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên gói tập",
      prop: "name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Số lượt chat trong ngày",
      prop: "limitChatBot"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.limitChatBot,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "limitChatBot", $$v);
      },
      expression: "form.limitChatBot"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Mã sản phẩm (shop)"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.form.syncId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "syncId", $$v);
      },
      expression: "form.syncId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Cho phép phương thức thanh toán"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentApple,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentApple", $$v);
      },
      expression: "form.enablePaymentApple"
    }
  }, [_vm._v("Apple")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentGoogle,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentGoogle", $$v);
      },
      expression: "form.enablePaymentGoogle"
    }
  }, [_vm._v("Google")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentAlepay,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentAlepay", $$v);
      },
      expression: "form.enablePaymentAlepay"
    }
  }, [_vm._v("AlePay")]), _c("el-checkbox", {
    model: {
      value: _vm.form.enablePaymentPaypal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "enablePaymentPaypal", $$v);
      },
      expression: "form.enablePaymentPaypal"
    }
  }, [_vm._v("Paypal")])], 1), _c("el-form-item", {
    attrs: {
      label: "Bài tập của gói"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleSelectBook
    }
  }, [_vm._v("Chọn bài tập")]), _c("el-table", {
    attrs: {
      data: _vm.books,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên bài tập",
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("img", {
          staticStyle: {
            "border-radius": "5px",
            width: "40px"
          },
          attrs: {
            src: _vm.$url.image(row.thumbnail),
            alt: ""
          }
        }), _c("br"), _c("label", {
          attrs: {
            for: ""
          }
        }, [_vm._v(" " + _vm._s(row.name) + " ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thể loại",
      prop: "bookCategory.name"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Tác giả",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _row$authors;
        var row = _ref2.row;
        return [((_row$authors = row.authors) === null || _row$authors === void 0 ? void 0 : _row$authors.length) > 0 ? _c("span", [_vm._v(_vm._s(row.authors.map(function (e) {
          return e.name;
        }).join(", ")))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số chương"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _row$bookChapters, _row$bookChapters2;
        var row = _ref3.row;
        return [((_row$bookChapters = row.bookChapters) === null || _row$bookChapters === void 0 ? void 0 : _row$bookChapters.length) > 0 ? _c("span", [_vm._v(" " + _vm._s((_row$bookChapters2 = row.bookChapters) === null || _row$bookChapters2 === void 0 ? void 0 : _row$bookChapters2.length) + " chương ")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var $index = _ref4.$index;
        return [_c("el-button", {
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeleteBook($index);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Chi tiết gói tập"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "8px"
    },
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleAddPackDetails
    }
  }, [_vm._v("Thêm thời hạn")]), _c("el-table", {
    attrs: {
      data: _vm.packDetails,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Tên lựa chọn gói",
      prop: "name",
      width: "220px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c("div", [_c("span", [_vm._v("- Tiếng Việt: " + _vm._s(row.name))])]), _c("div", [_c("span", [_vm._v("- Tiếng Anh: " + _vm._s(row.nameEn))])]), _c("div", [_c("span", [_vm._v("- Tiếng Nhật: " + _vm._s(row.nameJp))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giá",
      prop: "name",
      width: "220px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c("div", [_c("span", [_vm._v("- Giá sách (VNĐ): " + _vm._s(row.price))])]), _c("div", [_c("span", [_vm._v("- Giá sách (USD): " + _vm._s(row.priceEn))])]), _c("div", [_c("span", [_vm._v("- Giá sách (JPY): " + _vm._s(row.priceJp))])])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Apple Id",
      prop: "",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_c("span", [_vm._v(_vm._s(row.appleId))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Google Id",
      prop: "",
      width: "120px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var row = _ref8.row;
        return [_c("span", [_vm._v(_vm._s(row.googleId))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "PayPal Id",
      prop: "",
      width: "210px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var row = _ref9.row;
        return [_c("span", [_vm._v(_vm._s(row.paypalId))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Paypal Foreign Id",
      prop: "",
      width: "210px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var row = _ref10.row;
        return [_c("span", [_vm._v(_vm._s(row.paypalForeignId))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Ngày hạn",
      prop: "",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var row = _ref11.row;
        return [_c("span", [_vm._v(_vm._s(row.expiresDay))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Giảm giá (%)",
      prop: "",
      width: "100px",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var row = _ref12.row;
        return [_c("span", [_vm._v(_vm._s(row.discountPercent))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Số ngày miễn phí",
      prop: "",
      width: "140px",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var row = _ref13.row;
        return [_c("span", [_vm._v(_vm._s(row.freeDays))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Thao tác",
      width: "150px",
      fixed: "right",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var row = _ref14.row,
          $index = _ref14.$index;
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "flex-direction": "column",
            gap: "8px"
          }
        }, [_c("el-button", {
          staticStyle: {
            width: "100%"
          },
          attrs: {
            type: "primary",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.handleEditPackDetail(row, $index);
            }
          }
        }, [_vm._v("Cập nhật")]), _c("el-button", {
          staticStyle: {
            width: "100%",
            "margin-left": "0"
          },
          attrs: {
            type: "danger",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.handleDeletePackDetail($index);
            }
          }
        }, [_vm._v("Xóa")])], 1)];
      }
    }])
  })], 1)], 1), _vm.recommendBookStrategy.status == _vm.HighlightBookStrategyStatus.Pending ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loadingUpdate
    },
    on: {
      click: _vm.handleUpdatePending
    }
  }, [_vm._v("Cập nhật")]) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1), _c("FilterBookModal", {
    ref: "FilterBookModal",
    on: {
      "submit:ok": _vm.handleSelectBookOk
    }
  }), _c("PackDetailModal", {
    ref: "PackDetailModal",
    on: {
      "add-detail": _vm.addPackDetail,
      "update-detail": _vm.updatePackDetail
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;